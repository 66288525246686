<template>
  <!--  <b-overlay :show="preparingCells" rounded="sm" spinner-variant="danger">-->
  <div>
    <b-modal title="Import list of cells" v-model="openCellsList" ok-only @ok="primarMapBoosterByList">
      <b-form-textarea v-model="cellList"
                       placeholder="Paste comma or new line separated cells names..."></b-form-textarea>
    </b-modal>

    <div v-if="!orderPlaced" class="mt-2">
      <b-row>
        <b-col cols="7">
          <b-card class="px-2" no-body bg-variant="light">
            <!--            <b-container>-->
            <b-form inline>
              <b-form-select style="width: auto" v-if="deviceStore.getAgreementProvider === 'UKHO'"
                             v-model="selectedType_UKHO" size="sm"
                             class="mr-1" :options="[
                { value: 'AVCS', text: 'AVCS' },
                { value: 'ADP', text: 'ADP' },
                { value: 'eNP', text: 'eNP' }]"></b-form-select>
              <b-form-select v-if="deviceStore.getAgreementProvider === 'Primar'" v-model="selectedType_Primar"
                             size="sm" :options="[
                { value: 'ENC', text: 'ENC', disabled: true }]"></b-form-select>
              <b-form-select style="width: auto" v-model="selectedPeriod" size="sm" :options="[
                { value: '4', text: '3 m.' },
                { value: '1', text: '6 m.' },
                { value: '0', text: '12 m.' }]"></b-form-select>
            </b-form>
            <!--            </b-container>-->
          </b-card>
        </b-col>

        <b-col>
          <b-button
              class="mr-1" size="sm" variant="info" @click="importClipboard()">Import
          </b-button>

          <b-button v-if="!viewParent.editMode"
                    class="mr-1"
                    :disabled="cartStore.getCartLength === 0 || cartStore.getTotalPrice === undefined || cartStore.getTotalPrice === 0"
                    size="sm" variant="success" @click="placeOrder()">Submit
          </b-button>

          <b-button v-if="viewParent.editMode"
                    :disabled="cartStore.getCartLength === 0 || cartStore.getTotalPrice === undefined || cartStore.getTotalPrice === 0"
                    size="sm" variant="success" @click="updateOrder()">Update
          </b-button>


        </b-col>
      </b-row>
      <b-row style="height: 30px">
        <b-col>
            <span class="smt2s" v-if="cartStore.getTotalPrice">
              Total price: {{ getDisplayTotalPrice() }}<br>
            </span>
          <span class="smt2s" v-else-if="priceCalculating">
                Calculating price...
            </span>
        </b-col>

        <b-button v-b-tooltip.hover title="Select all" v-if="filterSelectAll && cartStore.getCartLength > 0"
                  class="btn shadow-none border-0 float-right" size="sm" variant="outline-secondary"
                  @click="toggleBands('on')">
          <b-icon icon="square" font-scale="1.2"></b-icon>
        </b-button>
        <b-button v-b-tooltip.hover title="Unselect all" v-if="!filterSelectAll && cartStore.getCartLength > 0"
                  class="btn shadow-none border-0 float-right" size="sm" variant="outline-secondary"
                  @click="toggleBands('off')">
          <b-icon icon="check-square" font-scale="1.2"></b-icon>
        </b-button>

      </b-row>
    </div>

    <b-table v-if="!orderPlaced && selectedType_UKHO === 'AVCS'" ref="orderstable" selectable select-mode="single"
             sticky-header="56vh"
             @row-selected="onRowSelected" sort-icon-left
             :items="visibleCellsRows()"
             :fields="visible_cells_fields">

      <template #cell(id)="row">
        <!--          <div v-if="deviceStore.getAgreementProvider === 'UKHO'">-->
        <span v-if="purchasedEarly(row.item.id)">
            <span class="small-green">{{ row.item.id }}</span><br>
            <span class="smt2s">Valid till: {{ getCellValidDate(row.item.id) }}</span>
          </span>
        <span v-else>
            {{ row.item.id }}
          </span>
      </template>

      <template #cell(band)="row">
        <span class="small-gray-card">{{ bandName(cellById(row.item.id).usageBand) }}</span>
      </template>

      <template #cell(bandid)="row">
        <span class="small-gray-card">{{ cellById(row.item.id).usageBand }}</span>
      </template>

      <template #cell(price)="row">
        {{ getCellDisplayPrice(row.item.id) }}
      </template>

      <template #cell(selected)="row">
        <b-checkbox switch :checked="!row.item.disabled" @change=toggleCellInclusion(row.item.id)></b-checkbox>
      </template>
    </b-table>

    <b-table v-if="!orderPlaced && selectedType_UKHO === 'ADP'" ref="orderstableadp" selectable select-mode="single"
             sticky-header="56vh"
             sort-icon-left
             :items="visibleADPRows()"
             :fields="visible_publications_fields">

      <template #cell(price)="row">
        {{ getADPDisplayPrice(row.item.name) }}
      </template>

      <template #cell(selected)="row">
        <b-checkbox switch :checked="!row.item.disabled" @change=toggleADPInclusion(row.item.name)></b-checkbox>
      </template>
    </b-table>

    <b-table v-if="!orderPlaced && selectedType_UKHO === 'eNP'" ref="orderstableenp" selectable select-mode="single"
             sticky-header="56vh"
             sort-icon-left
             :items="visibleENPRows()"
             :fields="visible_publications_fields">

      <template #cell(price)="row">
        {{ getENPDisplayPrice(row.item.id) }}
      </template>

      <template #cell(selected)="row">
        <b-checkbox switch :checked="!row.item.disabled" @change=toggleENPInclusion(row.item.id)></b-checkbox>
      </template>
    </b-table>

    <b-container v-if="orderPlaced" class="mt-2">
      <b-row>
        <b-col cols="8">
          <b-card no-body bg-variant="light" class="px-2 py-2">
            <span class="smt2s">
              Agreement: {{ deviceStore.getAgreementName }}<br>
              Ship: {{ userStore.getShipName }}
              <hr>
              <div v-if="deviceStore.getAgreementProvider === 'UKHO'">
                AVCS: {{ currentCartEnabledItemsLen }} items<br>
                AVCS period: {{ this.getDisplayPeriod() }} months<br>
                AVCS price: {{ getDisplayENCPrice() }}<br><br>

                ADP: {{currentCartEnabledADPItemsLen}} items<br>
                ADP price: {{ getDisplayADPPrice() }}<br><br>

                eNP: {{currentCartEnabledENPItemsLen}} items<br>
                eNP price: {{ getDisplayENPPrice() }}<br><br>
              </div>

              <div v-if="deviceStore.getAgreementProvider === 'Primar'">
                ENC: {{ currentCartEnabledItemsLen }} items<br>
                ENC period: {{ this.getDisplayPeriod() }} months<br>
                ENC price: {{ getDisplayTotalPrice() }} <br><br>
              </div>
                <b>Total price: {{ getDisplayTotalPrice() }}</b><br>
            </span>
          </b-card>
        </b-col>
        <b-col align-self="center">
          <b-button block size="sm" variant="success" @click="approveOrder()">Approve</b-button>
          <b-button block size="sm" variant="danger" @click="cancelOrder()">Cancel</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
  <!--  </b-overlay>-->
</template>

<script>
import {eventBus} from "@/main";
import * as utils from "@/utils/utils";
import {debounce} from "lodash"
import {myAxios, myAxiosRetry} from "@/services/myAxios";
import router from "@/router";
import {mapStores} from "pinia";
import {useRoutesStore} from "@/stores/routes";
import {useMomentaryStore} from "@/stores/momentary";
import {useDeviceStore} from "@/stores/device";
import {useUserStore} from "@/stores/user";
import {loadMSSChartsByRoute, sendPurchaseRequest, sendUpdateRequest} from "@/helpers/api";
import logbook from "@/views/logbook/index.vue";
import {useCartStore} from "@/stores/cart";
import {useCatalogsStore} from "@/stores/catalogs";

export default {
  name: "OrdersTable",
  // props: ['map'],
  data() {
    return {
      // sortBy: 'bandid',
      filterSelectAll: false,
      preparingCells: false,
      orderPlaced: false,
      viewParent: this.$parent.$parent.$parent.$parent,
      selectedType_UKHO: 'AVCS',
      selectedType_Primar: 'ENC',
      selectedPeriod: '4',
      priceCalculating: false,
      openCellsList: false,
      cellList: ""
    }
  },
  mounted() {
    // this.prepareCells()
    eventBus.$off('make-row-active')
    eventBus.$off('recalculate-total-price')
    eventBus.$off('recalculate-enp-price')
    eventBus.$off('recalculate-adp-price')

    eventBus.$off('toggle-chart-from-graph')
    eventBus.$on('toggle-chart-from-graph', (id) => {
      this.toggleCellInclusion(id, 'switch')
    })

    eventBus.$on('make-row-active', (id) => {
      this.onCellClicked(id)
    })

    eventBus.$on('recalculate-total-price', () => {
      this.recalculateCountTotalPriceByError()
    })

    eventBus.$on('recalculate-enp-price', () => {
      this.recalculateCountENPPriceByError()
    })

    eventBus.$on('recalculate-adp-price', () => {
      this.recalculateCountADPPriceByError()
    })


    // this.getHistory().then(resp => {
    //   this.deviceStore.addOrdersHistoryAVCS(resp.avcsCells)
    // })7
  },
  watch: {
    'cartStore.getCartLength'(newVal) {
      this.debounceCountTotalPrice()
      this.debounceCheckOrdersHistory()
    },
    'cartStore.getENPCartLength'(newVal) {
      this.debounceCountENPPrice()
      // TODO this.debounceCheckOrdersHistory()
    },
    'cartStore.getADPCartLength'(newVal) {
      this.debounceCountADPPrice()
      // TODO this.debounceCheckOrdersHistory()
    },
    selectedPeriod() {
      this.debounceCountTotalPrice()
      // this.debounceCountENPPrice()
      // this.debounceCountADPPrice()
    },
    selectedType_UKHO(newVal) {
      console.log(newVal)
    }

  },
  computed: {
    ...mapStores(useRoutesStore, useMomentaryStore, useUserStore, useDeviceStore, useCartStore, useCatalogsStore),
    // currentCartLen() {
    //   return this.$store.state.current.cart.s63cells.length
    // },
    currentCartEnabledItemsLen() {
      // const enabledItems = this.$store.state.current.cart.s63cells.filter(function (e) {
      const enabledItems = this.cartStore.getCart.filter(function (e) {
        return !e.disabled;
      })
      return enabledItems.length
    },
    currentCartEnabledENPItemsLen() {
      const enabledItems = this.cartStore.getENPCart.filter(function (e) {
        return !e.disabled;
      })
      return enabledItems.length
    },
    currentCartEnabledADPItemsLen() {
      const enabledItems = this.cartStore.getADPCart.filter(function (e) {
        return !e.disabled;
      })
      return enabledItems.length
    },
    debounceCountTotalPrice() {
      return debounce(this.countTotalPrice, 500);
    },
    debounceCountENPPrice() {
      return debounce(this.countENPPrice, 500);
    },
    debounceCountADPPrice() {
      return debounce(this.countADPPrice, 500);
    },
    recalculateCountTotalPriceByError() {
      return debounce(this.countTotalPrice, 2000);
    },
    recalculateCountENPPriceByError() {
      return debounce(this.countENPPrice, 2000);
    },
    recalculateCountADPPriceByError() {
      return debounce(this.countADPPrice, 2000);
    },
    debounceCheckOrdersHistory() {
      return debounce(this.checkOrdersHistory, 500);
    },
    visible_cells_fields() {
      return [
        {key: 'selected', label: 'Selected', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'id', label: 'ID', tdClass: 'small-black', sortable: true, thClass: 'small-gray'},
        // {key: 'bandid', label: 'Band ID', tdClass: 'small-black', thClass: 'small-gray', sortable: true},
        {key: 'band', label: 'Band', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'price', label: 'Price', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },
    visible_publications_fields() {
      return [
        {key: 'selected', label: 'Selected', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'name', label: 'ID', tdClass: 'small-black', sortable: true, thClass: 'small-gray'},
        {key: 'price', label: 'Price', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },
  },
  methods: {
    importClipboard() {
      this.openCellsList = true;
    },

    primarMapBoosterByList() {
      this.openCellsList = false;
      let cells = this.cellList.split(/[\n,]/);

      cells.forEach((item) => {
        eventBus.$emit('a1', item.trim())
        // this.map.onCellClicked(item.trim());
      });
      eventBus.$emit('a4');
      eventBus.$emit('a41');
      // this.map.dispatchSelectedCells();
    },

    getHistory() {
      return new Promise(resolve => {
            if (!this.deviceStore.getDeviceID) {
              resolve()
              return
            }

            if (this.deviceStore.getAgreementProvider === 'UKHO') {
              let URL = "/api/v1/mss/summary/deviceid/" + this.deviceStore.getDeviceID;
              myAxios.get(URL).then(resp => {
                console.log("aaaresp", resp.data)
                // resp.data = {}
                if (resp.data && !resp.data.avcsCells) {
                  this.momentaryStore.setAPIError(true, 'summary/deviceid')
                }

                resolve(resp.data);
              }).catch((error) => {
                console.log(error)
              }).finally(() => {
              })
            }

            if (this.deviceStore.getAgreementProvider === 'Primar') {
              let URL = "/api/v1/orders/deviceid/" + this.deviceStore.getDeviceID;
              myAxios.get(URL).then(resp => {
                console.log("aaaa", resp);
                resolve(resp.data);
              }).catch((error) => {
                console.log(error)
              }).finally(() => {
              })
            }
          }
      )
    },

    purchasedEarly(id) {
      const purchased = this.deviceStore.orderedData.ukho.avcs.filter(function (e) {
        return e.name === id;
      })

      return purchased.length > 0
    },

    getCellValidDate(id) {
      const purchased = this.deviceStore.orderedData.ukho.avcs.filter(function (e) {
        return e.name === id;
      })
      return purchased[0]['expiryDate']
    },

    getCurrency() {
      return this.deviceStore.getAgreementCurrency
    },

    checkOrdersHistory() {
      let isNeedRecalculate = false
      this.cartStore.getCart.forEach(item => {
        if (!item.disabled && this.purchasedEarly(item.id)) {
          this.toggleCellInclusion(item.id, 'off')
          isNeedRecalculate = true
        }
      })

      if (isNeedRecalculate) {
        this.debounceCountTotalPrice()
      }
    },

    countTotalPrice() {
      // alert(1)

      if (this.cartStore.getCartLength === 0) {
        this.priceCalculating = false;
        return
      }

      this.priceCalculating = true;

      // hide price while change period
      // this.$store.commit("current/setTotalPriceByPrimar",
      //     {usd: '?', rub: '?', rubineuro: '?'}
      // );

      // let agreementID = this.hasValidAgreement ? this.$store.state.proto.agreement.id : "-1"
      // let deviceID = this.$store.state.proto.order.device_id
      // let appType = this.$store.state.proto.order.application_id
      let deviceID = this.deviceStore.getDeviceID
      let agreement = this.deviceStore.getAgreementID
      let appType = this.deviceStore.getAgreementApplicationType
      let period = this.selectedPeriod

      console.log("aaaa", deviceID, agreement, appType)


      // if (this.selectedPeriod === '6') {
      //   period = '1'
      // } else if (this.selectedPeriod === '12') {
      //   period = '0'
      // }

      this.cartStore.calculatePrice({
        products: this.cartStore.getCart,
        periodBand: period,
        agreementID: agreement,
        deviceID: deviceID,
        appType: appType
      }).then(() => {
        this.priceCalculating = false;
      })

      //   this.$store.dispatch("current/getTotalPrice", {
      //     products: this.cartStore.getCart,
      //     periodBand: period,
      //     agreementID: agreement,
      //     deviceID: deviceID,
      //     appType: appType
      //   }).then(() => {
      //     this.priceCalculating = false;
      //   })
    },

    countENPPrice() {

      if (this.cartStore.getENPCartLength === 0)
        return

      this.priceCalculating = true;
      let deviceID = this.deviceStore.getDeviceID
      let agreement = this.deviceStore.getAgreementID
      let appType = this.deviceStore.getAgreementApplicationType
      let period = this.selectedPeriod

      this.cartStore.calculateENPPrice({
        products: this.cartStore.getENPCart,
        periodBand: period,
        agreementID: agreement,
        deviceID: deviceID,
        appType: appType
      }).then(() => {
        this.priceCalculating = false;
      })
    },

    countADPPrice() {
      if (this.cartStore.getADPCartLength === 0)
        return

      this.priceCalculating = true;
      let deviceID = this.deviceStore.getDeviceID
      let agreement = this.deviceStore.getAgreementID
      let appType = this.deviceStore.getAgreementApplicationType
      let period = this.selectedPeriod

      this.cartStore.calculateADPPrice({
        products: this.cartStore.getADPCart,
        periodBand: period,
        agreementID: agreement,
        deviceID: deviceID,
        appType: appType
      }).then(() => {
        this.priceCalculating = false;
      })
    },

    toggleBands(type) {
      if (type === 'on' || type === 'off') {
        this.filterSelectAll = !this.filterSelectAll
        this.cartStore.getCart.forEach(item => {
          this.toggleCellInclusion(item.id, type)
        })
        this.cartStore.getENPCart.forEach(item => {
          this.toggleENPInclusion(item.id, type)
        })
      } else {
        this.cartStore.getADPCart.forEach(item => {
          this.toggleADPInclusion(item.id, type)
        })
      }
      this.debounceCountTotalPrice()
      this.debounceCountENPPrice()
      this.debounceCountADPPrice()
    },

    placeOrder() {
      this.orderPlaced = true
    },

    updateOrder() {
      this.orderPlaced = true
    },

    cancelOrder() {
      this.orderPlaced = false
    },

    onCellClicked(id) {
      if (this.$refs.orderstable) {
        let x = this.$refs.orderstable.computedItems;
        let row = this.$refs.orderstable.computedItems.findIndex(x => x.id === id);
        console.log(id, row, this.items);
        this.$refs.orderstable.selectRow(row);
        // this.scrollToRow(row);//TODO
      }

      let cell = this.cartStore.getCart.find(o => o.id === id)
      if (cell) {
        // this.toggleCellInclusion(id, 'switch')
        cell.disabled = false
        this.debounceCountTotalPrice()
      }
    },

    approveOrder(payment_method = 'agreement') {
      // TODO make overlay
      this.preparingCells = true

      // alert(this.viewParent.editMode)

      let enabledItems = []
      this.enabledCartItems().forEach(item => {
        let enabledItem = {
          id: item.id,
          primarOrderTypeId: this.selectedPeriod,
          price: String(this.cartStore.getCellPrice(item.id)),
          monthCount: this.getDisplayPeriod(),
          type: this.deviceStore.getAgreementProvider === 'UKHO' ? 'AVCS maps' : 's63 maps'
        }
        enabledItems.push(enabledItem)
      })

      this.enabledADPCartItems().forEach(item => {
        let enabledItem = {
          id: item.name,
          price: String(this.cartStore.getADPPrice(item.name)),
          type: 'ADP'
        }
        enabledItems.push(enabledItem)
      })

      this.enabledENPCartItems().forEach(item => {
        let enabledItem = {
          id: item.id,
          price: String(this.cartStore.getENPPrice(item.id)),
          type: 'ENP'
        }
        enabledItems.push(enabledItem)
      })


      // TODO make comment
      let comment = this.deviceStore.getAgreementProvider === 'UKHO' ? 'AVCS purchase' : 'Primar purchase'
      let data = {
        deviceId: this.deviceStore.getDeviceID,
        price: String(this.cartStore.getTotalPrice),
        items: enabledItems,
        comment: comment,
        paymentMethod: "agreement",
        currency: this.deviceStore.getAgreementCurrency,
        agreementId: this.deviceStore.getAgreementID,
        applicationTypeId: this.deviceStore.getAgreementApplicationType,
        var: this.deviceStore.getAgreementProvider === 'UKHO' ? 'mss' : 'primar',
        domain: "ecdis-link.com"
      }

      if (this.viewParent.editMode) {
        sendUpdateRequest(data.items, this.viewParent.orderId).then(resp => {
          router.push({name: "transactions", params: {qrevent: 'reload-transactions'}})
        }).catch(err => {
          // TODO remove after network errors fix
          router.push({name: "transactions", params: {qrevent: 'reload-transactions'}})
        })
      } else {
        sendPurchaseRequest(data).then(resp => {
          router.push({name: "transactions", params: {qrevent: 'reload-transactions'}})
        }).catch(err => {
          // TODO remove after network errors fix
          router.push({name: "transactions", params: {qrevent: 'reload-transactions'}})
        })
      }

    },

    getENPDisplayPrice(id) {
      let price = this.cartStore.getENPPrice(id)
      if (price !== '?') {
        price = price + '£'
      }
      return price
    },

    getADPDisplayPrice(id) {
      let price = this.cartStore.getADPPrice(id)
      if (price !== '?') {
        price = price + '£'
      }
      return price
    },

    getCellDisplayPrice(id) {
      let price = this.cartStore.getCellPrice(id)
      if (price !== '?') {
        if (this.getCurrency() === 'USD')
          price = '$' + price
        if (this.getCurrency() === 'RUB')
          price = price + '₽'
      }
      return price
    },

    getDisplayENCPrice() {
      let price = this.cartStore.getTotalPrice
      if (price !== 0) {
        if (this.getCurrency() === 'USD')
          price = '$' + price
        if (this.getCurrency() === 'RUB')
          price = price + '₽'
      }
      return price
    },

    getDisplayENPPrice() {
      let enpPrice = this.cartStore.getENPTotalPrice
      return enpPrice + '£'
    },

    getDisplayADPPrice() {
      let adpPrice = this.cartStore.getADPTotalPrice
      return adpPrice + '£'
    },


    getDisplayTotalPrice() {
      let price = this.cartStore.getTotalPrice
      let enpPrice = this.cartStore.getENPTotalPrice
      let adpPrice = this.cartStore.getADPTotalPrice
      if (price !== 0) {
        if (this.getCurrency() === 'USD')
          price = '$' + price
        if (this.getCurrency() === 'RUB')
          price = price + '₽'
      }

      if (adpPrice > 0 || enpPrice > 0)
        price = price + ' + ' + Number(adpPrice + enpPrice).toFixed(2) + '£'

      return price
    },

    getDisplayPeriod() {
      if (this.selectedPeriod === '4')
        return '3'
      if (this.selectedPeriod === '1')
        return '6'
      if (this.selectedPeriod === '0')
        return '12'
    },

    aaa8(id) {
      alert(id)
    },

    toggleCellInclusion(id, type = 'switch') {
      let status = this.cartStore.updateCell({id: id, type: type})

      if (status) {
        eventBus.$emit('a1', id)
        eventBus.$emit('a11', id)
      } else {
        eventBus.$emit('a2', id)
        eventBus.$emit('a22', id)
      }

      if (type === 'switch')
        this.countTotalPrice()
    },


    toggleENPInclusion(id, type = 'switch') {
      let status = this.cartStore.updateENP({id: id, type: type})

      // if (status) {
      //   eventBus.$emit('a1', id)
      // } else {
      //   eventBus.$emit('a2', id)
      // }

      if (type === 'switch')
        this.countENPPrice()
    },

    toggleADPInclusion(id, type = 'switch') {
      let status = this.cartStore.updateADP({id: id, type: type})

      // if (status) {
      //   eventBus.$emit('a1', id)
      // } else {
      //   eventBus.$emit('a2', id)
      // }

      if (type === 'switch')
        this.countADPPrice()
    },

    bandName(code) {
      return utils.getBandName(code);
    },

    cellById(id) {
      return this.catalogsStore.getItemsById(id)
      // return this.$store.getters.cellById(id)
    },

    onRowSelected(items) {
      if (items.length > 0) {
        this.showCellOnMap(items[0].id, this.summary)
        this.highlightCell(items[0].id)
      }
    },

    sortBands(a, b) {
      return this.cellById(a.id).usageBand - this.cellById(b.id).usageBand
    },

    visibleCellsRows() {
      let x = this.cartStore.getCart.slice().reverse()
      let y = x.sort(this.sortBands)
      return y
    },

    visibleADPRows() {
      return this.cartStore.adpCart
    },

    visibleENPRows() {
      return this.cartStore.enpCart
    },

    enabledADPCartItems() {
      const enabledItems = this.cartStore.getADPCart.filter(function (e) {
        return !e.disabled
      })
      return enabledItems.slice().reverse()
    },

    enabledENPCartItems() {
      const enabledItems = this.cartStore.getENPCart.filter(function (e) {
        return !e.disabled
      })
      return enabledItems.slice().reverse()
    },

    enabledCartItems() {
      const enabledItems = this.cartStore.getCart.filter(function (e) {
        return !e.disabled
      })
      return enabledItems.slice().reverse()
    },

    highlightCell(id) {
      eventBus.$emit('make-cell-active', id)
    },

    showCellOnMap(id, items) {
      eventBus.$emit('pane2cell', {id: id, items: items, color: 'green'})
      const url = 'https://win1hetz.bgeo.fi:5005/' + id + '/{z}/{x}/{y}'
      eventBus.$emit('show-cell-on-map', url)
    },
  }
}
</script>
<style scoped>
button:hover {
  color: black;
  background-color: white;
  outline: none !important;
}
</style>