import {defineStore} from 'pinia'
import dayjs from "dayjs";
import {useCatalogsStore} from "@/stores/catalogs";

export const useMomentaryStore = defineStore('momentary', {
    state: () => ({
        isBusy: false,
        isAPIError: false,
        APIErrorText: '',
        isOrdersBusy: false,
        isChartsByLegReady: true,
        route: {
            chartsbyroute: [],
            adpbyroute: [],
            enpbyroute: [],
            chartsbylegs: [],
            pubsbylegs: [],
        },
        charts: {
            listByRoute: [],
            cellsByRoute: [],
            graphByRoute: []
        },
        graphs: {
            chartsGraph: []
        }
    }),
    getters: {
        isChartsByLegLoaded: (state) => state.route.isChartsByLegReady,
        getENPByRoute: (state) => state.route.enpbyroute,
        getADPByRoute: (state) => state.route.adpbyroute,
        getChartsByRoute: (state) => state.route.chartsbyroute,

        getCellsByRoute: (state) => state.charts.cellsByRoute,
        getGraphByRoute: (state) => state.charts.graphByRoute,
        getListByRoute: (state) => state.charts.listByRoute,

        getChartsGraph: (state) => state.graphs.chartsGraph,
    },
    actions: {
        setBusy(flag) {
            this.isBusy = flag
        },
        setIsChartsByLegLoadedFlag(flag) {
            this.isChartsByLegReady = flag
        },
        setCellsByRoute(val) {
          this.charts.cellsByRoute = val
        },
        setChartsGraph(val) {
          this.graphs.chartsGraph = val
        },
        setAPIError(flag, text = '') {
            this.isAPIError = flag
            if (text)
                this.APIErrorText = ' (' + text + ')'
            else
                this.APIErrorText = ''
        },
        setOrdersBusy(flag) {
            this.isOrdersBusy = flag
        },
        addChartsByRoute(val) {
            this.route.chartsbyroute = val
        },
        addADPByRoute(val) {
            val["1"].forEach(item => {
                this.route.adpbyroute.push({id: item, disabled: false})
            })
        },
        addENPByRoute(val) {
            val["1"].forEach(item => {
                this.route.enpbyroute.push({id: item, disabled: false})
            })
        },
        // addAllPublications() {
        //     let catalogsStore = useCatalogsStore()
        //
        //     catalogsStore.getENP.products.forEach(item => {
        //         this.route.enpbyroute.push({id: item.id, title: item.title, disabled: true})
        //     })
        //     catalogsStore.getADP1.products.forEach(item => {
        //         this.route.adpbyroute.push({id: item.id, title: item.title, disabled: true})
        //     })
        //     catalogsStore.getADP2.products.forEach(item => {
        //         this.route.adpbyroute.push({id: item.id, title: item.title, disabled: true})
        //     })
        //     catalogsStore.getADP3.products.forEach(item => {
        //         this.route.adpbyroute.push({id: item.id, title: item.title, disabled: true})
        //     })
        // },
        addChartsByLegs(val) {
            this.route.chartsbylegs = val
            this.isChartsByLegLoaded = true
        },
        addPubsByLegs(val) {
            this.route.pubsbylegs = val
        },
        clearChartsByRoute() {
            this.route.chartsbyroute = []
        },
        clearChartsByLegs() {
            this.route.chartsbylegs = []
        },
    },
    persist: false
})
