<template>
  <div style="z-index:2;" id="ordersbyroutemap" ref="ordersbyroutemap"
       :style="{ height: height + 'px', minHeight: '400px' }"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import {FolioLeaf} from "@/my-leaf/charts/folio-leaf";
import {mapStores} from "pinia";
import {useCatalogsStore} from "@/stores/catalogs";
import {useDeviceStore} from "@/stores/device";
import {loadMSSChartsByRoute, loadMSSDataByRoute} from "@/helpers/api";
import {useRoutesStore} from "@/stores/routes";
import {useMomentaryStore} from "@/stores/momentary";
import {ChartsByRouteLeaf} from "@/my-leaf/charts/charts-by-route-leaf";
import {drawCharts} from "@/my-leaf/routes/components/route-graphs";

export default {
  name: "OrdersByRouteMap",
  props: ['height'],
  data() {
    return {
      map: new ChartsByRouteLeaf(),
    }
  },
  methods: {
    showFolioCell(cellName) {
      this.map.highlightCell(cellName)
    },
    switchCellSelected(cellName, isSelected) {
      this.map.switchCell(cellName, isSelected)
    },
    init_map() {
      return new Promise((resolve, reject) => {
        this.map.init(this.$refs.ordersbyroutemap);

        const allCharts = this.catalogsStore.getItems;
        const purchasedList = this.deviceStore.orderedData.ukho.avcs;
        if (purchasedList === undefined || purchasedList.length === 0) {
          // alert('purchasedList')
          window.location.reload()
        }


        const purchasedIds = purchasedList.map(item => item.name);

        // Filter for purchased/owned cells only

        console.log("aaa a", allCharts)

        this.prepareCells().then(() => {

          let allChartsByRoute = this.momentaryStore.getChartsByRoute;
          let allFolioCharts = this.deviceStore.orderedData.ukho.avcs;

          console.log("1234 aaaa", allChartsByRoute)
          console.log("1234 aaaa", allFolioCharts)

          let currentDate = new Date();
          let threeDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 3));
          let fiveDaysLater = new Date(currentDate.setDate(currentDate.getDate() + 5));

          let newArray = allChartsByRoute.map(chart => {
            let folioChart = allFolioCharts.find(folio => folio.name === chart);
            return {
              name: chart,
              title: folioChart ? folioChart.title : null,
              expiryDate: folioChart ? folioChart.expiryDate : null,
              isExpired: folioChart ? new Date(folioChart.expiryDate) < threeDaysAgo : false,
              isInFolio: !!folioChart,
              isSelected: !folioChart
            };
          });

          // let newArrayFiltered = newArray.filter(chart => chart.expiryDate === null || new Date(chart.expiryDate) > fiveDaysLater);
          let newArrayFiltered = newArray.filter(chart => !chart.isInFolio || new Date(chart.expiryDate) < fiveDaysLater);


          console.log("1234 a", allChartsByRoute)
          console.log("1234 b", newArrayFiltered)
          console.log("1234 d", allCharts)



          // let purchasedCharts = newArrayFiltered.filter(chart => {
          //   return newArrayFiltered.some(filteredChart => filteredChart.name === chart.name);
          // }).map(chart => {
          //   let routeChart = newArrayFiltered.find(route => route.name === chart.name);
          //   return {
          //     ...chart,
          //     ...routeChart
          //   };
          // });

          let purchasedCharts = newArray.map(chart => {
            let routeChart = allCharts.find(folio => folio.id === chart.name);
            return {
              ...chart,
              ...routeChart
            };
          });

          console.log("1234 e", purchasedCharts)

          this.momentaryStore.setCellsByRoute(purchasedCharts)

          this.map.drawAllCells(purchasedCharts);
          this.map.showRoute()

          // setTimeout(() => {
          //   drawCharts.call(this.map, 'chart')
          // }, 2000)
          // drawCharts.call(this.map, 'chart')

        })


        // this.prepareCells().then(() => {
        // let purchasedIds = this.momentaryStore.getChartsByRoute
        // let purchasedIds = newArrayFiltered
        //  const purchasedCharts = allCharts.filter(chart => {
        //    return purchasedIds.includes(chart.id);
        //  });

        // let purchasedIds = newArrayFiltered.filter(chart => chart.expiryDate === null || new Date(chart.expiryDate) > fiveDaysLater);

        // this.map.showRoute()
        // this.map.drawAllCells(purchasedCharts);
        // resolve();


        // })

      });

    },
    prepareCells() {
      return new Promise(resolve => {
            let points = []
            this.routesStore.getShipWaypoints.forEach(item => {
              points.push({lat: item.lat, lon: item.lon})
            })

            loadMSSChartsByRoute(points).then(() => {
              if (this.currentCartLen === 0) {
                this.selectCellByRoute().then(() => {
                  resolve()
                })
              } else {
                resolve()
              }
            })
          }
      )
    },
  },

  computed: {
    ...mapStores(useDeviceStore, useCatalogsStore, useRoutesStore, useMomentaryStore),
  },

};
</script>

<style>
#ordersbyroutemap {
  border: solid 1px dimgray;
}

.leaflet-interactive:hover {
  stroke: #ffa011;
  stroke-width: 4;
}
</style>