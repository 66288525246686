<template>
  <div>
    <canvas style="height: 100px" id="graph1"></canvas>
  </div>
</template>

<script>

import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import {eventBus} from "@/main";
import {mapStores} from "pinia";
import {useRoutesStore} from "@/stores/routes";

export default {
  name: "Graps",
  mounted() {
    // eventBus.$off('show-graphs')
    // eventBus.$on('show-graphs', () => {
    //   this.drawGraph()
    // })
  },
  computed: {
    ...mapStores(useRoutesStore),
  },
  methods: {
    drawGraph() {
      let route = this.routesStore.getShipWaypoints
      let x = []
      let y = []

      route.forEach(item => {
        x.push(item.id)
        y.push(item.minDepth)
      })


      const ctx = document.getElementById('graph1');

      const zoomOptions = {
        limits: {
          x: {min: 0, max: 100, minRange: 10},
          // y: {min: 0, max: 100, minRange: 2}
        },
        // pan: {
        //   enabled: true,
        //   mode: 'xy',
        // },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'x',
          // onZoomComplete({chart}) {
          //   // This update is needed to display up to date zoom level in the title.
          //   // Without this, previous zoom level is displayed.
          //   // The reason is: title uses the same beforeUpdate hook, and is evaluated before zoom.
          //   chart.update('none');
          // }
        }
      };

      // const scaleOpts = {
      //   reverse: true,
      //   ticks: {
      //     callback: (val, index, ticks) => index === 0 || index === ticks.length - 1 ? null : val,
      //   },
      //   grid: {
      //     borderColor: 'black',
      //     color: 'rgba( 0, 0, 0, 0.1)',
      //   },
      //   title: {
      //     display: true,
      //     text: (ctx) => ctx.scale.axis + ' axis',
      //   }
      // };
      // const scales = {
      //   x: {
      //     position: 'top',
      //   },
      //   y: {
      //     position: 'right',
      //   },
      // };
      // Object.keys(scales).forEach(scale => Object.assign(scales[scale], scaleOpts));

      Chart.register(zoomPlugin);
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: x,
          datasets: [{
            label: 'depth [m]',
            data: y,
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            zoom: zoomOptions,
            // title: {
            //   display: true,
            //   position: 'bottom',
            //   text: (ctx) => 'Zoom: '
            // }
          },
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });
    }
  }
}
</script>


<style scoped>

</style>