import dayjs from "dayjs";
import {loadMSSChartsByLegs, loadMSSChartsByRoute, loadMSSDataByRoute, loadTides} from "@/helpers/api";
import Chart from "chart.js/auto";
import Highcharts from "highcharts/highcharts-gantt"
// import Highcharts from "highcharts"
import Plotly from "plotly.js/dist/plotly"

import {useRoutesStore} from "@/stores/routes";
import zoomPlugin from "chartjs-plugin-zoom";
import 'chartjs-adapter-date-fns';
import {eventBus, routes_map} from "@/main";
import {useMomentaryStore} from "@/stores/momentary";
import accessibility from 'highcharts/modules/accessibility';
import 'highcharts/modules/mouse-wheel-zoom';
// accessibility(Highcharts);

function prepareWindows(canvasId, width, height, withImage = true) {
    // const mymap = document.getElementById('mymap');
    const mymap = document.getElementById('app');
    mymap.style.position = 'relative';

    const popup = document.createElement('div');
    popup.style.position = 'absolute';
    popup.style.top = '10px';
    popup.style.right = '10px';
    popup.style.width = width + 'px';
    popup.style.height = height + 'px';
    popup.style.zIndex = '5000';
    popup.style.backgroundColor = "white"
    popup.style.padding = "15px";
    popup.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
    popup.style.borderRadius = "10px";

    const moveButton = document.createElement('button');
    moveButton.id = 'moveButton';
    moveButton.innerHTML = '≡';
    moveButton.style.position = 'absolute';
    moveButton.style.top = '3px';
    moveButton.style.left = '3px';
    moveButton.style.border = 'none';
    moveButton.style.background = 'transparent';
    moveButton.style.fontSize = '12px';
    moveButton.style.cursor = 'pointer';
    // moveButton.style.cursor = 'grab';

    const closeButton = document.createElement('button');
    closeButton.innerHTML = '✖';
    closeButton.style.position = 'absolute';
    closeButton.style.top = '3px';
    closeButton.style.right = '3px';
    closeButton.style.border = 'none';
    closeButton.style.background = 'transparent';
    closeButton.style.fontSize = '12px';
    closeButton.style.cursor = 'pointer';

    closeButton.onclick = () => {
        mymap.removeChild(popup);
        this.ukcSafeDepth = Number(this.routesStore.getShipNoGo)
        this.addNoGoLayer()
    };

    if (withImage)
        popup.innerHTML = "<div style=\"display: flex; align-items: center; gap: 10px;\"><img height=\"100px\" src=\"https://img.freepik.com/free-psd/luxury-yacht-bow-watercraft-elegance-nautical-vessel_632498-25525.jpg\">" +
            "<div><canvas id='" + canvasId + "' width='450' height='180' ></canvas></div></div>"
    else
        popup.innerHTML = "<div id='" + canvasId + "' width='450' height='180' ></div>"

    popup.appendChild(moveButton);
    popup.appendChild(closeButton);
    mymap.appendChild(popup);

// Переменные для отслеживания состояния перетаскивания
    let isDragging = false;
    let offsetX = 0;
    let offsetY = 0;

// Обработчик события нажатия кнопки мыши
moveButton.addEventListener('mousedown', function(e) {
        isDragging = true;
        // Получаем текущие координаты мыши относительно элемента
        offsetX = e.clientX - popup.offsetLeft;
        offsetY = e.clientY - popup.offsetTop;
        // Меняем курсор, чтобы показать, что элемент перетаскивается
        popup.style.cursor = 'grabbing';
        e.preventDefault(); // Предотвращаем выделение текста при перетаскивании
    });

// Обработчик события перемещения мыши
    document.addEventListener('mousemove', function(e) {
        if (!isDragging) return;
        // Вычисляем новые координаты элемента
        let x = e.clientX - offsetX;
        let y = e.clientY - offsetY;

        // Ограничиваем перемещение элемента, чтобы он не выходил за пределы родительского контейнера
        let mymapWidth = mymap.offsetWidth;
        let mymapHeight = mymap.offsetHeight;
        let popupWidth = popup.offsetWidth;
        let popupHeight = popup.offsetHeight;

        x = Math.min(Math.max(0, x), mymapWidth - popupWidth);
        y = Math.min(Math.max(0, y), mymapHeight - popupHeight);

        // Устанавливаем новые координаты элемента
        popup.style.left = x + 'px';
        popup.style.top = y + 'px';
    });

// Обработчик события отпускания кнопки мыши
    document.addEventListener('mouseup', function() {
        isDragging = false;
        // Возвращаем курсор в исходное состояние
        popup.style.cursor = 'grab';
    });

    popup.onclick = function (e) {
        e.stopPropagation();
    }

    popup.oncontextmenu = function (e) {
        e.stopPropagation();
    }

    popup.onmousedown = function (e) {
        e.stopPropagation();
    }

    // popup.onmouseup = function (e) {
    //     e.stopPropagation();
    // }

    popup.onwheel = function (e) {
        e.stopPropagation();
    }

    // popup.ondragstart = function (e) {
    //     e.stopPropagation();
    // }

    // popup.ondrag = function (e) {
    //     e.stopPropagation();
    // }

    // popup.ondragend = function (e) {
    //     e.stopPropagation();
    // }
}

function prepareContextMenuWindow(parentId, width, height) {
    const mymap = document.getElementById(parentId);
    mymap.style.position = 'relative';

    const popup = document.createElement('div');
    popup.style.position = 'absolute';
    popup.style.top = '10px';
    popup.style.right = '10px';
    popup.style.width = width + 'px';
    popup.style.height = height + 'px';
    popup.style.zIndex = '1000';
    popup.style.backgroundColor = "white"
    popup.style.padding = "15px";
    popup.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
    popup.style.borderRadius = "10px";

    const closeButton = document.createElement('button');
    closeButton.innerHTML = '✖';
    closeButton.style.position = 'absolute';
    closeButton.style.top = '3px';
    closeButton.style.right = '3px';
    closeButton.style.border = 'none';
    closeButton.style.background = 'transparent';
    closeButton.style.fontSize = '12px';
    closeButton.style.cursor = 'pointer';

    closeButton.onclick = () => {
        mymap.removeChild(popup);
        this.ukcSafeDepth = Number(this.routesStore.getShipNoGo)
        this.addNoGoLayer()
    };

    popup.appendChild(closeButton);
    mymap.appendChild(popup);

    popup.onclick = function (e) {
        e.stopPropagation();
    }

    popup.onwheel = function (e) {
        e.stopPropagation();
    }

    popup.ondragstart = function (e) {
        e.stopPropagation();
    }

    popup.ondrag = function (e) {
        e.stopPropagation();
    }

    popup.ondragend = function (e) {
        e.stopPropagation();
    }
}

// export function drawFolio() {
//     let routesStore = useRoutesStore()
//     let momentaryStore = useMomentaryStore()
//
//     let points = []
//     this.routesStore.getShipWaypoints.forEach(item => {
//         points.push({lat: item.lat, lon: item.lon})
//     })
//
//     loadMSSChartsByLegs(points).then(() => {
//
//         let aaa1 = momentaryStore.route.chartsbylegs
//
//         let bbb = [];
//         let occurrences = {};
//
//         // Collect occurrences of each string
//         for (let key in aaa1) {
//             let numKey = parseInt(key);
//             aaa1[key].forEach(item => {
//                 if (!occurrences[item]) {
//                     occurrences[item] = [];
//                 }
//                 occurrences[item].push(numKey);
//             });
//         }
//
//         let cats = ['Berthing', 'Harbour', 'Approach', 'Coastal', 'General', 'Overview']
//
//         // Process occurrences to create bbb
//         for (let name in occurrences) {
//             let ranges = [];
//             let currentRange = { start: null, end: null, name: 'overview', y: 1};
//             let occurrenceList = occurrences[name].sort((a, b) => a - b); // Sort numerically
//
//             occurrenceList.forEach(occurrence => {
//                 if (currentRange.start === null) {
//                     currentRange = { start: occurrence, end: occurrence };
//                 } else if (occurrence === currentRange.end + 1) {
//                     currentRange.end = occurrence;
//                 } else {
//                     ranges.push(currentRange);
//                     currentRange = { start: occurrence, end: occurrence };
//                 }
//
//                 currentRange.y = Number(6 - name.charAt(2))
//                 // currentRange.color = 'blue'
//
//                 const rndInt = Math.floor(Math.random() * 6) + 1
//                 currentRange.color = rndInt > 2 ? 'rgba(74, 201, 244, 0.5)' : 'rgba(83, 231, 115, 0.5)'
//
//
//             });
//
//             ranges.push(currentRange);
//
//             ranges.forEach(range => {
//                 bbb.push({
//                     name: name,
//                     data: [range],
//                 });
//             });
//         }
//
//         prepareWindows.call(this, 'graphcharts', 600, 300, false)
//
//         Highcharts.chart('graphcharts', {
//             scrollbar: {
//                 enabled: true
//             },
//             chart: {
//                 height: 280,
//                 type: 'gantt',
//                 zoomType: 'xy' // Enable zooming on the x-axis
//             },
//             legend: {enabled: false},
//             title: {
//                 text: null
//             },
//             tooltip: {
//                 formatter: function () {
//                     return this.series.name;
//                 }
//             },
//             plotOptions: {
//                 series: {
//                     pointWidth: 15,
//                     states: {
//                         hover: {
//                             enabled: false
//                         }
//                     },
//                     point: {
//                         events: {
//                             click: function (event) {
//                                 let coordinates = {
//                                     lat: routesStore.getShipWaypoints[this.category].lat,
//                                     lon: routesStore.getShipWaypoints[this.category].lon
//                                 }
//                                 routes_map.panToCoordinates(coordinates, 12)
//
//                                 // Toggle color on click
//                                 this.update({
//                                     color: this.color === 'rgba(74, 201, 244, 0.5)' ? 'rgba(244, 156, 74, 0.5)'
//                                         : this.color === 'rgba(244, 156, 74, 0.5)' ? 'rgba(74, 201, 244, 0.5)' : this.color
//                                 });
//                             }
//                         }
//                     }
//                 }
//             },
//             xAxis: {
//                 max: 10 // Show no more than 10 points on the x-axis by default
//             },
//             yAxis: {
//                 type: 'category',
//                 categories: ['Berthing', 'Harbour', 'Approach', 'Coastal', 'General', 'Overview'],
//                 title: {
//                     enabled: false
//                 },
//                 uniqueNames: false
//             },
//             series: bbb
//         });
//     })
// }

// export function drawAAA(cellName) {
//     alert('drawAAA')
//     // updateSeriesColor(cellName, 'red')
//     drawCharts.upda
// }

export function drawCharts(type) {
    let routesStore = useRoutesStore()
    let momentaryStore = useMomentaryStore()

    let points = []
    this.routesStore.getShipWaypoints.forEach(item => {
        points.push({lat: item.lat, lon: item.lon})
    })

    loadMSSChartsByLegs(points).then(() => {

        let aaa1 = momentaryStore.route.chartsbylegs

        let bbb = [];
        let occurrences = {};

        // Collect occurrences of each string
        for (let key in aaa1) {
            let numKey = parseInt(key);
            aaa1[key].forEach(item => {
                if (!occurrences[item]) {
                    occurrences[item] = [];
                }
                occurrences[item].push(numKey);
            });
        }

        console.log("aaa aaa1", aaa1)
        console.log("aaa occurrences", occurrences)


        let cats = ['Berthing', 'Harbour', 'Approach', 'Coastal', 'General', 'Overview']

        // Process occurrences to create bbb
        for (let name in occurrences) {
            let ranges = [];
            let currentRange = { start: null, end: null, name: 'overview', y: 1};
            let occurrenceList = occurrences[name].sort((a, b) => a - b); // Sort numerically

            occurrenceList.forEach(occurrence => {
                if (currentRange.start === null) {
                    currentRange = { start: occurrence, end: occurrence };
                } else if (occurrence === currentRange.end + 1) {
                    currentRange.end = occurrence;
                } else {
                    ranges.push(currentRange);
                    currentRange = { start: occurrence, end: occurrence };
                }

                currentRange.y = Number(6 - name.charAt(2))
                // currentRange.color = 'blue'

                let x = this.momentaryStore.getCellsByRoute
                let y = x.some(cell => cell.id === name && cell.isInFolio)
                let y2 = x.some(cell => cell.id === name && cell.isSelected)

                // const rndInt = Math.floor(Math.random() * 6) + 1
                currentRange.color = y ? 'rgba(83, 231, 115, 0.5)' : 'rgba(74,148,255,0.5)'
                if (!y && !y2)
                    currentRange.color = 'rgba(244, 156, 74, 0.5)'


            });

            ranges.push(currentRange);

            ranges.forEach(range => {
                bbb.push({
                    name: name,
                    data: [range],
                });
            });
        }

        bbb.forEach((item, index) => {
            let start = Number(item.data[0].start)
            let end = Number(item.data[0].end)

            if (end - start === 0) {
                item.data[0].end = end + 1
                item.data[0].len = 1
            } else {
                item.data[0].start = start
                item.data[0].end = end
                item.data[0].len = end - start + 1
            }
        })

        // bbb.forEach(item => {
        //     if (item.data.length === 1) {
        //         item.data[0].start = item.data[0].start - 0.2
        //         item.data[0].end = item.data[0].end + 0.2
        //     } else {
        //         item.data[0].start = item.data[0].start + 0.3
        //         item.data[0].end = item.data[0].end - 0.3
        //     }
        // })

        console.log("aaa bbb", bbb)


        this.momentaryStore.setChartsGraph(bbb)

        prepareWindows.call(this, 'graphcharts', 600, 300, false)


        const chart = Highcharts.chart('graphcharts', {
            scrollbar: {
                enabled: true
            },
            chart: {
                height: 280,
                type: 'gantt',
                zoomType: 'xy' // Enable zooming on the x-axis
            },
            legend: {enabled: false},
            title: {
                text: null
            },
            tooltip: {
                formatter: function () {
                    let type = ""
                    // console.log(this.series)
                    // console.log(this.series.data)
                    // console.log(this.series.data.color)
                    if (this.series.data[0].color === 'rgba(74,148,255,0.5)')
                        type = ' (selected) ' + this.series.data[0].start + ":" + this.series.data[0].end + " [" + this.series.data[0].len + "]"

                    if (this.series.data[0].color === 'rgba(83, 231, 115, 0.5)')
                        type = ' (in folio) ' + this.series.data[0].start + ":" + this.series.data[0].end + " [" + this.series.data[0].len + "]"

                    if (this.series.data[0].color === 'rgba(244, 156, 74, 0.5)')
                        type = ' (unselected) ' + this.series.data[0].start + ":" + this.series.data[0].end + " [" + this.series.data[0].len + "]"


                    return this.series.name + type;
                }
            },
            plotOptions: {
                series: {
                    pointWidth: 15,
                    states: {
                        hover: {
                            enabled: false
                        }
                    },
                    point: {
                        events: {
                            click: function (event) {

                                // this.map.highlightCell(this.series.name)
                                eventBus.$emit('highlight-onroute-cell', this.series.name)

                                let coordinates = {
                                    lat: routesStore.getShipWaypoints[this.category].lat,
                                    lon: routesStore.getShipWaypoints[this.category].lon
                                }
                                routes_map.panToCoordinates(coordinates, 12)
                                // eventBus.$emit('toggle-chart-from-graph', this.series.name)

                                // Toggle color on click
                                console.log("ggg", this)
                                // this.update({
                                //     color: this.color === 'rgba(74,148,255,0.5)' ? 'rgba(244, 156, 74, 0.5)'
                                //         : this.color === 'rgba(244, 156, 74, 0.5)' ? 'rgba(74,148,255,0.5)' : this.color
                                // });
                            }
                        }
                    }
                }
            },
            xAxis: {
                // max: 10 // Show no more than 10 points on the x-axis by default
            },
            yAxis: {
                type: 'category',
                categories: ['Berthing', 'Harbour', 'Approach', 'Coastal', 'General', 'Overview'],
                title: {
                    enabled: false
                },
                uniqueNames: false
            },
            series:  this.momentaryStore.getChartsGraph
        });

        let cellSeries
        let cellColor

        chart.selectCell = function (seriesName, isSelected) {
            console.log("gantt", this.series)

            // if (this.chartGantt) {
            //     alert(12)
            //     this.chartGantt.zoomOut()
            // }

            this.zoomOut()

            if (this.activeCell && this.activeCell.cellSeries) {
                this.activeCell.cellSeries.update({color: this.activeCell.cellColor});
            }

            const series = this.series.find(s => s.name === seriesName);
            console.log("gantt f", series)
            if (series) {
                cellSeries = series.points[0]
                cellColor = series.points[0].color
                series.points[0].onMouseOver();
                // series.points[0].setState('hover');
                series.points[0].update({color: 'rgba(204,204,204,0.5)'});
            }

            this.activeCell = {
                cellSeries: cellSeries,
                cellColor: cellColor
            }
        };

        chart.updateSeriesColor = function (seriesName, isSelected) {
            console.log("gantt", this.series)

            const series = this.series.find(s => s.name === seriesName);
            console.log("gantt f", series)
            if (series) {
                // series.setState('hover');
                // series.scrollIntoView({ behavior: 'smooth', block: 'center' });
                // series.showTooltip();
                series.points[0].onMouseOver();
                if (!isSelected) {
                    series.points[0].update({color: 'rgba(244, 156, 74, 0.5)'});
                    // this.activeCell = {
                    //     cellSeries: series.points[0].color,
                    //     cellColor: series.points[0]
                    // }
                } else {
                    series.points[0].update({color: 'rgba(74,148,255,0.5)'});
                    // this.activeCell = {
                    //     cellSeries: series.points[0].color,
                    //     cellColor: series.points[0]
                    // }
                }
            }

        };
        this.chartGantt = chart;
        console.log("aaa gantt", this);
    })
}

// export function drawCharts2() {
//     prepareWindows.call(this, 'graphchartsplotly', 750, 420, false)
//
//
//     const data = [
//         {
//             color: 'lightgreen',
//             name: 'NO1A3000',
//             start: 0.5,
//             end: 1.5,
//             y: 0
//         },
//         {
//             color: 'red',
//             name: 'NO2B1204',
//             start: 0,
//             end: 7,
//             y: 1
//         },
//         {
//             color: 'lightblue',
//             name: 'NO2B1212',
//             start: 7,
//             end: 20,
//             y: 1
//         },
//         {
//             color: 'yellow',
//             name: 'NO3B0412',
//             start: 2,
//             end: 18,
//             y: 2
//         },
//         {
//             color: 'green',
//             name: 'NO3B0422',
//             start: 2,
//             end: 18,
//             y: 2
//         },
//         {
//             color: 'lightgreen',
//             name: 'NO4D1211',
//             start: 5,
//             end: 9,
//             y: 3
//         },
//         {
//             color: 'lightgreen',
//             name: 'NO4D1212',
//             start: 0,
//             end: 3,
//             y: 4
//         },
//         {
//             color: 'lightgreen',
//             name: 'NO5E1210',
//             start: 12,
//             end: 20,
//             y: 5
//         },
//         {
//             color: 'lightgreen',
//             name: 'NO6G1208',
//             start: 0,
//             end: 2,
//             y: 6
//         },
//         {
//             color: 'lightgreen',
//             name: 'NO6G12788',
//             start: 18,
//             end: 20,
//             y: 6
//         }
//     ];
//
//     const yAxisLabels = new Map([
//         ['Berthing', 5],  // Швартовка
//         ['Harbour', 4],   // Гавань
//         ['Approach', 3],  // Подход
//         ['Coastal', 2],   // Прибрежная зона
//         ['General', 1],   // Общий вид
//         ['Overview', 0]   // Обзор
//     ]);
//
//     // Преобразование входных данных в формат Plotly
//     // Группировка задач по цвету для создания единой легенды
//     const traces = data.reduce((acc, item) => {
//         // Поиск существующей группы по цвету или создание новой
//         const colorGroup = acc.find(g => g.marker.color === item.color) || {
//             type: 'bar',              // Тип графика - столбчатый
//             orientation: 'h',         // Горизонтальная ориентация
//             marker: {color: item.color}, // Цвет столбца
//             x: [],                    // Массив длительностей
//             y: [],                    // Массив позиций по Y
//             base: [],                 // Массив начальных точек
//             name: item.color,         // Имя группы для легенды
//             width: 0.3,              // Ширина столбца (можно настроить от 0 до 1)
//             // Шаблон всплывающей подсказки (можно кастомизировать)
//             hovertemplate: 'Task: %{text}<br>' +
//                 'Start: %{base}<br>' +
//                 'End: %{x}<br>' +
//                 '<extra></extra>',
//             text: []                  // Массив названий задач
//         };
//
//         // Добавление новой группы цвета, если её ещё нет
//         if (!acc.includes(colorGroup)) {
//             acc.push(colorGroup);
//         }
//
//         // Заполнение данных для текущей задачи
//         colorGroup.x.push(item.end - item.start);  // Длительность
//         colorGroup.y.push(item.y);                 // Позиция по Y
//         colorGroup.base.push(item.start);          // Начальное время
//         colorGroup.text.push(item.name);           // Название задачи
//
//         return acc;
//     }, []);
//
//     // Настройка внешнего вида диаграммы
//     const layout = {
//         title: 'Task Schedule',           // Заголовок графика
//         width: 700,                      // Ширина в пикселях
//         height: 400,                      // Высота в пикселях
//         xaxis: {
//             title: 'Time',                // Подпись оси X
//             zeroline: false,              // Отключение нулевой линии
//             showgrid: true,               // Показывать сетку
//             gridwidth: 1,                 // Толщина линий сетки
//             gridcolor: 'rgb(233, 233, 233)', // Цвет сетки
//             dtick: 1                      // Шаг делений (можно изменить)
//         },
//         yaxis: {
//             autorange: 'reversed',        // Обратный порядок оси Y
//             showgrid: true,               // Показывать сетку
//             gridwidth: 1,                 // Толщина линий сетки
//             gridcolor: 'rgb(233, 233, 233)', // Цвет сетки
//             // Настройка подписей оси Y из Map
//             ticktext: Array.from(yAxisLabels.keys()),
//             tickvals: Array.from(yAxisLabels.values()),
//             tickmode: 'array'             // Режим отображения делений
//         },
//         hovermode: 'closest',            // Режим всплывающих подсказок
//         hoverdistance: 1,                // Дистанция срабатывания подсказок
//         margin: {                        // Отступы графика
//             l: 80,                      // слева
//             r: 20,                       // справа
//             t: 20,                       // сверху
//             b: 20                        // снизу
//         },
//         barmode: 'overlay',              // Режим наложения столбцов
//         showlegend: true,                // Показывать легенду
//         bargap: 0.3                      // Промежуток между группами столбцов
//     };
//
//     // Настройка панели управления
//     const config = {
//         responsive: false,               // Отключение автомасштабирования
//         displayModeBar: true,            // Показывать панель инструментов
//         displaylogo: false               // Скрыть логотип Plotly
//     };
//
//     // Создание графика
//     Plotly.newPlot('graphchartsplotly', traces, layout, config);
// }

export function drawDepths() {

    let routesStore = useRoutesStore()

    prepareWindows.call(this, 'graphtide', 600, 200)

    let route = routesStore.getShipWaypoints
    let x = []
    let y = []

    route.forEach(item => {
        x.push(item.id)
        y.push(item.minDepth)
    })

    const ctx = document.getElementById('graphtide');

    const zoomOptions = {
        limits: {
            x: {min: 0, max: 100, minRange: 10},
        },
        // pan: {
        //   enabled: true,
        //   mode: 'xy',
        // },
        zoom: {
            wheel: {
                enabled: true,
            },
            pinch: {
                enabled: true
            },
            mode: 'x',
            // onZoomComplete({chart}) {
            //   // This update is needed to display up to date zoom level in the title.
            //   // Without this, previous zoom level is displayed.
            //   // The reason is: title uses the same beforeUpdate hook, and is evaluated before zoom.
            //   chart.update('none');
            // }
        }
    };

    const plugin = {
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart, args, options) => {
            const {ctx} = chart;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = options.color || '#edcea3';
            ctx.fillRect(28, 10, chart.width - 39, chart.height - 15);
            ctx.restore();
        }
    };

    Chart.register(zoomPlugin);
    new Chart(ctx, {
        type: 'line',
        data: {
            labels: x,
            datasets: [{
                label: 'depth [m]',
                data: y,
                borderWidth: 1,
                cubicInterpolationMode: 'monotone',
                tension: 10,
                fill: true,
                // backgroundColor: '#c36f00',
            }]
        },
        // plugins: [plugin],
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                // customCanvasBackgroundColor: {
                //     color: '#dd8709',
                // },
                legend:
                    {
                        display: false
                    },
                zoom: zoomOptions,
                // title: {
                //   display: true,
                //   position: 'bottom',
                //   text: (ctx) => 'Zoom: '
                // }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    reverse: true,
                    // max:0
                }
            },
            onClick: (event, elements, chart) => {
                if (elements[0]) {
                    const i = elements[0].index;
                    let coordinates = {
                        lat: routesStore.getShipWaypoints[i].lat,
                        lon: routesStore.getShipWaypoints[i].lon
                    }
                    routes_map.panToCoordinates(coordinates, 12)
                }
            }
        }
    });
}

export function drawTide(coordinates) {
    let utc = require("dayjs/plugin/utc");
    let timezone = require("dayjs/plugin/timezone");

    dayjs.extend(utc);
    dayjs.extend(timezone)

    let date = dayjs().format("YYYY-MM-DD");
    console.log("tide", date)

    console.log("aaa", dayjs())
    console.log("aaa", dayjs().format())

    let a = "America/Halifax"
    let d = "2025-02-04T04:00+0000"

    const d1 = dayjs.tz(d, a);
    d1.format(); // => 2013-11-18T11:55:00+08:00
    d1.toISOString(); // => 2013-11-18T03:55:00.000Z

    console.log("aaa1", d1)
    console.log("aaa1", d1.format(), d1.toISOString())
    console.log("aaa1", dayjs().utcOffset())
    console.log("aaa1", d1['$offset'])


    loadTides(date, coordinates.lat, coordinates.lng).then(resp => {
        let tides = resp.heights


        let tideTimezone = resp.timezone//"America/New_York"

        // dayjs.tz.setDefault(tideTimezone);

        let hours = []
        let values = []

        console.log("tide", tides)
        let timeOffset = dayjs(tides[0].date).tz(tideTimezone).hour()
        tides.forEach(tide => {
            let a = dayjs(tide.date)
            let b = a.utc().hour()
            let d = a.tz(tideTimezone).hour()

            let hoursWithTZ = dayjs(tide.date).utc().hour()
            let minutesOffsetTZ = dayjs(tide.date).tz(tideTimezone).utcOffset()

            let utcHours = (hoursWithTZ * 60 - minutesOffsetTZ) / 60
            if (utcHours < 0)
                utcHours = ((24 - hoursWithTZ) * 60 - minutesOffsetTZ) / 60

            // console.log("tide2", a.hour(), b, d, utcHours)

            console.log("aaaaaaaa", dayjs(tide.date).toISOString())
            console.log("aaaaaaaa", dayjs(tide.date).tz(tideTimezone).hour())
            console.log("aaaaaaaa", dayjs(tide.date)['$offset'])
            const date = new Date(tide.date)
            const hour = date.getUTCHours();
            hours.push(hour)
            // hours.push(dayjs(tide.date).tz(tideTimezone).hour())
            // hours.push(dayjs(tide.date).tz(tideTimezone).hour() + dayjs(tide.date)['$offset']/60)


            let AA1 = dayjs(tide.date)
            let AA2 = tideTimezone
            let AA3 = dayjs(AA1, AA2).utcOffset()
            console.log("ddd", AA1.toISOString(), AA2, AA3)

            values.push(tide.height)
            console.log("tide24", dayjs(tide.date).tz(tideTimezone).utcOffset(), b, d, utcHours)
            // console.log("tide", a.hour(), b.hour(), d.hour())
            // let hour1 = dayjs(tide.date).hour()
            // let hour2 = dayjs(tide.date).utc().hour()
            // let hour3 = dayjs(tide.date).local().hour()
            // console.log("tide", hour1, hour2, hour3)
        })

        console.log("tide", tides)

        prepareWindows.call(this, 'myChart1', 600, 200)

//         const mymap = document.getElementById('mymap');
//         mymap.style.position = 'relative';
//
//         const popup = document.createElement('div');
//         popup.style.position = 'absolute';
//         popup.style.top = '10px';
//         popup.style.right = '10px';
//         popup.style.width = "500px";
//         // popup.style.height = "300px";
//         popup.style.zIndex = '1000';
//         popup.style.backgroundColor = "white"
//         popup.style.padding = "15px";
//         popup.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.2)";
//         popup.style.borderRadius = "10px";
//
//         const closeButton = document.createElement('button');
//         closeButton.innerHTML = '✖';
//         closeButton.style.position = 'absolute';
//         closeButton.style.top = '10px';
//         closeButton.style.right = '10px';
//         closeButton.style.border = 'none';
//         closeButton.style.background = 'transparent';
//         closeButton.style.fontSize = '18px';
//         closeButton.style.cursor = 'pointer';
//
// // Добавляем обработчик клика для закрытия popup
//         closeButton.onclick = () => {
//             mymap.removeChild(popup);
//             this.ukcSafeDepth = Number(this.routesStore.getShipNoGo)
//             this.addNoGoLayer()
//         };
//
// // Добавляем текст и кнопку в popup
// //                 popup.innerHTML = "Hello World!";
//         popup.innerHTML = "<canvas id='myChart1' width='400' height='400'></canvas>";
//         popup.appendChild(closeButton);
//
//         mymap.appendChild(popup);
//
//         popup.onclick = function (e) {
//             e.stopPropagation();
//         }

        const ctx = document.getElementById('myChart1')
        console.log("iiii", ctx)

        const zeroPad = (num, places) => String(num).padStart(places, '0')

        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: hours,
                datasets: [{
                    label: 'tide [m]',
                    data: values,
                    borderWidth: 1
                }]
            },
            options: {
                plugins: {
                    // title: {
                    //     display: true,
                    //     text: 'Привет' // Основной заголовок
                    // },
                    tooltip: {
                        callbacks: {
                            title: function (context) {
                                return date + ' ' + zeroPad(context[0].label, 2) + ":00 UTC";
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Time, UTC' // Пример названия оси X
                        }
                    },
                    y: {
                        // title: {
                        //     display: true,
                        //     text: 'Tide, m' // Пример названия оси X
                        // },
                        beginAtZero: true
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                onClick: (event, elements, chart) => {
                    if (elements[0]) {
                        const i = elements[0].index;
                        this.ukcSafeDepth = Number(Number(this.routesStore.getShipDraught) -
                            Number(chart.data.datasets[0].data[i])).toFixed(1);
                        this.addNoGoLayer();
                    }
                }
            }
        });


    })
}


