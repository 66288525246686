<template>
  <div>

    <b-modal title="Date warning" v-model="isShowFarDateWarning" ok-only>
      <div class="text-sm-left" id="up">
        The date is too far away to get a tidal forecast. Enter a new date to get an up-to-date forecast.
      </div>
    </b-modal>

    <div v-if="false" align="center" class="text-centercenter text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong>&nbsp;Loading ...</strong>
    </div>
    <div v-else>
      <code>
        Draft: {{ routesStore.getShipDraught }}m, Length: {{ routesStore.getShipLength }}m, Width:
        {{ routesStore.getShipWidth }}m, Displacement: {{ routesStore.getShipDisplacement }}t<br>
      </code>
      <b-table v-if="routesStore.getShipWaypointsCount > 0" v-model="aaa" selectable sticky-header select-mode="single"
               ref="pptable"
               style="max-height: 400px;"
               @row-selected="onRowSelected" @row-clicked="onRowClicked" :items="items()" :fields="visible_fields"
               :tbody-tr-class="rowClass">

        <template #cell(name)="data">
          <span class='smt2s'>{{ data.index }}</span>
        </template>

        <template #cell(ukc.time)="data">
          <!--          <span class='smt2s'>{{ data.item.ukc.time }}</span>-->
          <span class='smt2g'>{{ dayjs.unix(data.item.ukc.time).format("DD/MM HH:mm") }}</span>
        </template>

        <template #cell(ukc.time2)="data">
          <span v-if="data.item?.ukc.time2 !== undefined">
            <b-form-input size="sm" type="text" v-model="data.item.ukc.time2"
                          :state="checkDateTime(data.item.ukc.time2, data.item.ukc.time1)"
                          @input='(value) => inputHandler(value, data.index, "time")'>
            </b-form-input>
          </span>
        </template>

        <template #cell(minDepth)="data">
          <span v-if="data.item?.minDepth !== undefined">
            <b-form-input size="sm" type="text" v-model="data.item.minDepth"
                          @input="(value) => inputHandler(value, data.index)">
            </b-form-input>
          </span>
        </template>

        <template #cell(ukc.speed)="data">
          <span v-if="data.item?.ukc?.speed !== undefined">
            <b-form-input size="sm" type="text" v-model="data.item.ukc.speed"
                          @input="(value) => inputHandler(value, data.index)">
            </b-form-input>
          </span>
        </template>

        <template #cell(ukc.displacement)="data">
          <span v-if="data.item?.ukc?.displacement !== undefined">
            <b-form-input size="sm" type="text" v-model="data.item.ukc.displacement"
                          @input="(value) => inputHandler(value, data.index)">
            </b-form-input>
          </span>
        </template>

        <template #cell(ukc.tide)="data">
          <span v-if="data.item?.ukc?.tide !== undefined">
            <b-form-input size="sm" type="text" v-model="data.item.ukc.tide"
                          @input="(value) => inputHandler(value, data.index)">
            </b-form-input>
          </span>
        </template>

        <template #cell(ukc.zoc)="data">
          <span v-if="data.item?.ukc?.zoc !== undefined">
            <b-form-input size="sm" type="text" min="0" max="5" step="0.5" v-model="data.item.ukc.zoc" lang="en"
                          @input="(value) => inputHandler(value, data.index)">
            </b-form-input>
          </span>
        </template>

        <template #cell(ukc.draft)="data">
          <span v-if="data.item?.ukc?.draft  !== undefined">
            <b-form-input size="sm" type="text" v-model="data.item.ukc.draft"
                          @input="(value) => inputHandler(value, data.index)">
            </b-form-input>
          </span>
        </template>

        <template #cell(ukc.ukcPolicy)="data">
          <span v-if="data.item?.ukc?.ukcPolicy  !== undefined">
            <b-form-input size="sm" type="text" v-model="data.item.ukc.ukcPolicy"
                          @input="(value) => inputHandler(value, data.index)">
            </b-form-input>
          </span>
        </template>

        <template #cell(ukc.isOpenWater)="data">
          <span v-if="data.item?.ukc?.isOpenWater !== undefined">
            <b-form-checkbox size="sm" v-model="data.item.ukc.isOpenWater"
                             @input="(value) => inputHandler(value, data.index)">
            </b-form-checkbox>
          </span>
        </template>

      </b-table>
    </div>

  </div>
</template>

<script>
import {eventBus, routes_map} from "@/main";
import store from "@/store";
import {myAxios} from "@/services/myAxios";
import {useRoutesStore} from "@/stores/routes";

import dayjs from "dayjs";
import {mapStores} from "pinia";
import {useUserStore} from "@/stores/user";
import {lineOffset} from "@turf/turf";
import {loadTides, loadZOC} from "@/helpers/api";
import {midLatlon} from "@/utils/utils";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

let customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  name: "PassagePlanTable",
  data() {
    return {
      prevRowIndex: -1,
      currentRowIndex: -1,
      centerRowIndex: -1,
      focusWPIndex: -1,
      aaa: [],
      weatherInWP: [],
      currentIndex: 0,
      isUKCReady: false,
      isShowFarDateWarning: false,
    }
  },
  mounted() {
    eventBus.$off('select-pptable-row')
    eventBus.$on('select-pptable-row', (id) => {
      // this.currentRowIndex = id
      this.$refs.pptable.selectRow(id);
      const tbody = this.$refs.pptable.$el.querySelector('tbody')
      const row = tbody.querySelectorAll('tr')[id]
      row.scrollIntoView({block: 'center'})
    })
  },
  watch: {},
  computed: {
    dayjs() {
      return dayjs
    },
    ...mapStores(useRoutesStore),
    visible_fields() {
      return [
        {key: 'name', label: '#', tdClass: 'small-black', thClass: 'small-gray-header'},
        // {key: 'ukc.time', label: 'Time', tdClass: 'small-black', thClass: 'small-gray-header'},
        // {key: 'ukc.time1', label: 'Time', tdClass: 'small-black', thClass: 'small-gray-header'},
        {key: 'ukc.time2', label: 'Time UTC', tdClass: 'small-black-time', thClass: 'small-gray-header'},
        {key: 'minDepth', label: 'Chart.D m', tdClass: 'small-black-zoc', thClass: 'small-gray-header'},
        {key: 'ukc.isSafe', label: 'Safe route', tdClass: 'small-black', thClass: 'small-gray-header'},
        {key: 'ukc.safeDepth', label: 'Safe.D m', tdClass: 'small-black', thClass: 'small-gray-header'},
        {key: 'ukc.ukc', label: 'UKC m', tdClass: 'small-black', thClass: 'small-gray-header'},
        {key: 'ukc.isOpenWater', label: 'Open waters', tdClass: 'small-black', thClass: 'small-gray-header'},
        {key: 'ukc.draft', label: 'Draft m', tdClass: 'small-black-zoc', thClass: 'small-gray-header'},
        {key: 'ukc.speed', label: 'Speed kn', tdClass: 'small-black-zoc', thClass: 'small-gray-header'},
        {key: 'ukc.displacement', label: 'Disp. t', tdClass: 'small-black-tide', thClass: 'small-gray-header'},
        {key: 'ukc.ukcPolicy', label: 'Policy m', tdClass: 'small-black-zoc', thClass: 'small-gray-header'},
        {key: 'ukc.squat', label: 'Squat m', tdClass: 'small-black', thClass: 'small-gray-header'},
        {key: 'ukc.tide', label: 'Tide m', tdClass: 'small-black-tide', thClass: 'small-gray-header'},
        {key: 'ukc.zoc', label: 'ZOC m', tdClass: 'small-black-zoc', thClass: 'small-gray-header'},
        // {key: 'ukc.zoc1', label: 'ZOC (m)', tdClass: 'small-black-zoc', thClass: 'small-gray-header'},

        // {key: 'ukc.displacement', label: '*Displacement', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'ukc.blockVolume', label: '*Block', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'ukc.blockCoefficient', label: '*BlockCoefficient', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },
  },
  methods: {
    checkDateTime(value, prevValue) {
      let dateAndTime = value.split(' ')
      if (dateAndTime.length !== 2)
        return false

      let date = dateAndTime[0].split('/')
      if (date.length !== 2)
        return false

      if (date[0] > 31)
        return false

      if (date[1] > 12)
        return false

      let time = dateAndTime[1].split(':')
      if (time.length !== 2)
        return false

      if (time[0] > 23)
        return false

      if (time[1] > 59)
        return false

      let datetime = dayjs(dayjs().year() + "/" + value, "YYYY/DD/MM HH:mm")
      let valid = dayjs(datetime, "YYYY/DD/MM HH:mm").isValid()

      // let date1 = dayjs.unix(item.ukc.time1)
      // let date2 = dayjs.unix(datetimeunix)
      // let diff = Math.abs(date1.diff(date2, 'day'))

      if (!valid)
        return false

      return true
    },

    selectRow(index) {
      this.$refs.pptable.unselectRow(index);
      setTimeout(() => {
        this.$refs.pptable.selectRow(index);
      }, 100)
      this.currentRowIndex = index
      // eventBus.$emit('click-wp', index)

    },
    onRowClicked(row, index) {
      window.dispatchEvent(new Event('resize'));
      routes_map.zoomToWaypoint(index, 10, false, true)
    },
    onRowSelected(items) {
    },
    items() {
      return this.routesStore.getShipWaypoints;
    },
    editCellHandler(data, name) {
      // this.items = this.items1.map(item => ({...item, isEdit: false}));
      this.items[data.index].isEdit = true;
      this.selectedCell = name
    },
    getUpdatedValue(a, b) {
      console.log("update", a, b)
    },
    setTide1(ukcItem, value) {
      let utc = require("dayjs/plugin/utc");
      let timezone = require("dayjs/plugin/timezone");

      dayjs.extend(utc);
      dayjs.extend(timezone)


      console.log("uuu START", value, ukcItem);

      let datetime = dayjs.utc(dayjs().year() + "/" + value, "YYYY/DD/MM HH:mm")
      let datetimeunix = dayjs(datetime, "YYYY/DD/MM HH:mm").unix()

      // let datetime1 = dayjs.tz(dayjs().year() + "/" + value, "YYYY/DD/MM HH:mm", "Etc/UTC")
      // let datetimeunix1 = dayjs(datetime, "YYYY/DD/MM HH:mm").unix()


      console.log("uuu AAA", datetime.toISOString())
      console.log("uuu AAA", datetimeunix)
      // console.log("uuu AAA", datetimeunix1)

      let tideAtTime = -1
      let timediff = Number.MAX_SAFE_INTEGER
      ukcItem.tides.forEach(tide => {
        // console.log("uuu tides", tide);
        // console.log("uuu tides", tide.dt);
        let diff = Math.abs(tide.dt - datetimeunix)
        // console.log("uuu diff", diff, timediff);
        if (diff < timediff) {
          timediff = diff
          tideAtTime = tide.height
        }
      })
      //
      ukcItem.tide = tideAtTime

      console.log("uuu START1", tideAtTime);
      // ukcItem.tide = -1
      this.$refs.pptable.refresh();
    },
    setTide(ukcItem, datetimeunix) {
      console.log("tttt START", datetimeunix, ukcItem);
      let tideAtTime = -1
      let timediff = Number.MAX_SAFE_INTEGER
      ukcItem.tides.forEach(tide => {
        console.log("tttt tides", tide);
        console.log("tttt tides", tide.dt);
        let diff = Math.abs(tide.dt - datetimeunix)
        console.log("tttt diff", diff, timediff);
        if (diff < timediff) {
          timediff = diff
          tideAtTime = tide.height
        }
      })

      ukcItem.tide = tideAtTime
      this.$refs.pptable.refresh();
    },
    inputHandler(value, index, from = "common") {
      dayjs.extend(customParseFormat)

      this.isShowFarDateWarning = false
      console.log("aaa12", value, index, from);
      this.items().forEach((item, itemIndex) => {

        if (index !== itemIndex)
          return;

        if (from === 'time') {
          if (!this.checkDateTime(value))
            return
          else {
            let datetime = dayjs(dayjs().year() + "/" + value, "YYYY/DD/MM HH:mm")
            let datetimeunix = dayjs(datetime, "YYYY/DD/MM HH:mm").unix()

            let date1 = dayjs.unix(item.ukc.time1)
            let date2 = dayjs.unix(datetimeunix)
            let diff = Math.abs(date1.diff(date2, 'day'))

            if (diff > 7) {
              this.isShowFarDateWarning = true
              return
            }

            item.ukc.time = datetimeunix
            item.ukc.time1 = datetimeunix

            this.setTide1(item.ukc, value)

            // let tideAtTime = -1
            // let unixAtTime = -1
            // let timediff = Number.MAX_SAFE_INTEGER
            // item.ukc.tides.forEach(tide => {
            //   let diff = Math.abs(tide.dt - datetimeunix)
            //   if (diff < timediff) {
            //     timediff = diff
            //     unixAtTime = tide.dt
            //     tideAtTime = tide.height
            //   }
            // })
            //
            // item.ukc.tide = tideAtTime

            // console.log("aaa TIDES", unixAtTime, tideAtTime)
            //
            // console.log("aaa TIME1", value)
            // console.log("aaa TIME2", datetime)
            // console.log("aaa TIME4", dayjs(datetime, "YYYY/DD/MM HH:mm"))
            // console.log("aaa TIME4", dayjs(datetime, "YYYY/DD/MM HH:mm").isValid())
            // console.log("aaa TIME4", dayjs(datetime, "YYYY/DD/MM HH:mm"))
            // console.log("aaa TIME12", dayjs(datetime, "YYYY/DD/MM HH:mm").unix())

          }
        } else {
          let H = Number(item.minDepth)
          let V = Number(item.ukc.speed)
          let T = Number(item.ukc.draft)
          let Z = Number(item.ukc.zoc)
          let Td = Number(item.ukc.tide)

          item.ukc.blockCoefficient = Number((item.ukc.displacement / item.ukc.blockVolume).toFixed(2))
          item.ukc.squat = item.ukc.isOpenWater ? Number((item.ukc.blockCoefficient * V * V / 100).toFixed(1)) : Number((item.ukc.blockCoefficient * V * V / 50).toFixed(1))
          let Sq = Number(item.ukc.squat)
          item.ukc.ukc = (H + Td - T - Sq).toFixed(2)
          // item.ukc.ukcPolicy = Math.max(3.5, T / 2).toFixed(1)
          // item.ukc.ukcPolicy = item.ukc.isOpenWater ? 3.5 : 0.4
          // item.ukc.zoc = item.ukc.isOpenWater ? Number((4 + 20 * H / 100).toFixed(1)) : Number((0.5 + 1 * H / 100).toFixed(1))
          let UKCP = Number(item.ukc.ukcPolicy)
          item.ukc.safeDepth = (T + Sq + UKCP + Z).toFixed(2)
          let SD = Number(item.ukc.safeDepth)
          item.ukc.isSafe = Number(H + Td - SD) > 0 ? 'YES' : 'NO'
        }
      })

      routes_map.addNoGoLayer()
      this.$refs.pptable.refresh();

    },
    datem(seconds) {
      let date = this.routesStore.getShipStartDate
      let time = this.routesStore.getShipStartTime
      let datetime = dayjs(date + " " + time, "YYYY-MM-DD HH:mm")
      let datetimenew = datetime.add(seconds, "seconds")
      // let shortdate = datetimenew.format("DD/MM HH:mm")

      return datetimenew.unix()
    },
    a1() {
      let a = Date.now()
      console.log("ddd", a)
    },
    calculateUKC() {
      if (this.items()[0].ukc === undefined) {

        // this.a1()

        let points = [];
        let lat, lon
        this.items().forEach((item, index) => {
          if (index === 0) {
            lat = item.lat
            lon = item.lon
          } else {
            if (index < this.items().length) {
              let middle = midLatlon([lat, lon], [item.lat, item.lon])
              points.push({lat: middle[0], lon: middle[1]});
              lat = item.lat
              lon = item.lon
            }
          }
        })

        points.push(points[points.length - 1]);

        // let points = [];
        // this.items().forEach((item) => {
        //   points.push({lat:item.lat, lon:item.lon});
        // })

        // loadTides('2025-01-28', "42.0", "29").then((resp) => {
        //   console.log("AAA 12", resp)
        // })

        loadZOC(points).then((zocs) => {
          // this.items().forEach((item, index) => {
          //   item.ukc = {}
          //   item.ukc.speed = Number(this.routesStore.importedRoute[index]?.['@_speed'] ?? -1)
          //   if (item.ukc.speed === -1 && index > 0)
          //     item.ukc.speed = this.routesStore.getShipSpeed
          //
          //   if (item.ukc.speed === -1 && index === 0)
          //     item.ukc.speed = 0
          //
          //   item.ukc.time = this.datem(this.routesStore.getShipLegInfo(index).fromStartSeconds)
          //   item.ukc.time1 = this.datem(this.routesStore.getShipLegInfo(index).fromStartSeconds)
          //
          //   let date = dayjs.unix(item.ukc.time).format("YYYY-MM-DD")
          //   loadTides(date, item.lat, item.lon, 1).then((resp) => {
          //     console.log("date", resp.heights)
          //     item.ukc.tides = resp.heights
          //     item.ukc.tide = item.ukc.tides[0].height
          //     console.log("ddd TIDE", item.ukc.tide)
          //     this.$refs.pptable.refresh();
          //   })
          //
          // })

          // console.log("ddd REFRESH")
          // this.isUKCReady = true
          // this.$refs.pptable.refresh();


          this.items().forEach((item, index) => {
            item.ukc = {}
            item.ukc.speed = Number(this.routesStore.importedRoute[index]?.['@_speed'] ?? -1)
            if (item.ukc.speed === -1 && index > 0)
              item.ukc.speed = this.routesStore.getShipSpeed

            if (item.ukc.speed === -1 && index === 0)
              item.ukc.speed = 0

            item.ukc.time = this.datem(this.routesStore.getShipLegInfo(index).fromStartSeconds)
            item.ukc.time1 = this.datem(this.routesStore.getShipLegInfo(index).fromStartSeconds)
            item.ukc.time2 = dayjs.unix(this.datem(this.routesStore.getShipLegInfo(index).fromStartSeconds)).format("DD/MM HH:mm")


            let date = dayjs.unix(item.ukc.time).subtract(1, 'day').format("YYYY-MM-DD")
            // date = date().subtract(1, 'day');
            console.log("date1122", date)

            // item.ukc.tide = 0
            loadTides(date, item.lat, item.lon, 5).then((resp) => {
              console.log("date", resp.heights)
              item.ukc.tides = resp.heights
              item.ukc.tide = 0
              // item.ukc.tide = item.ukc.tides[0].height
              // this.$refs.pptable.refresh();
              setTimeout(() => {
                this.setTide1(item.ukc, item.ukc.time2)
                // this.inputHandler(0, 0)
              }, 100)
            })

            // let legLength = Number(this.routesStore.getShipLegInfo(index).fromPreviousMeters)
            // let speedKN = Number(item.ukc.speed)
            // let speedMS = speedKN * 0.514444
            // let seconds = speedMS === 0 ? 10 : Math.round(legLength / speedMS)
            // let time2 = dayjs.duration({
            //   seconds: 1,
            //   minutes: 2,
            //   hours: 3,
            //   days: 4,
            //   months: 5,
            //   years: 6
            // }).format('YYYY-MM-DDTHH:mm:ss')

            // console.log("ddd", item.ukc.tides[0].height)

            item.ukc.tide = 0
            item.ukc.isOpenWater = true

            item.ukc.zoc1 = zocs[index]

            let zoc = zocs[index]
            switch (zoc) {
              case 'A1':
                item.ukc.zoc = Number((0.5 + 1 * item.minDepth / 100).toFixed(1))
                break
              case 'A2':
                item.ukc.zoc = Number((1 + 2 * item.minDepth / 100).toFixed(1))
                break
              case 'B':
                item.ukc.zoc = Number((1 + 2 * item.minDepth / 100).toFixed(1))
                break
              case 'C':
                item.ukc.zoc = Number((2 + 5 * item.minDepth / 100).toFixed(1))
                break
              case 'D':
                item.ukc.zoc = Number((2 + 5 * item.minDepth / 100).toFixed(1))
                break
              case 'U':
              case 'U (data not assessed)':
                item.ukc.zoc = Number((4 + 20 * item.minDepth / 100).toFixed(1))
                break
              default:
                item.ukc.zoc = Number((4 + 20 * item.minDepth / 100).toFixed(1))
                break
            }

            item.ukc.draft = Number(this.routesStore.getShipDraught)
            item.ukc.displacement = Number(this.routesStore.getShipDisplacement)
            item.ukc.blockVolume = Number(this.routesStore.getShipWidth * this.routesStore.getShipLength * item.ukc.draft)
            item.ukc.blockCoefficient = Number((item.ukc.displacement / item.ukc.blockVolume).toFixed(2))
            item.ukc.squat = Number((item.ukc.blockCoefficient * item.ukc.speed * item.ukc.speed / 100).toFixed(1))
            item.ukc.ukc = Number((item.minDepth + item.ukc.tide - item.ukc.draft - item.ukc.squat).toFixed(2))
            // item.ukc.ukcPolicy = item.ukc.isOpenWater ? 3.5 : 0.4
            // item.ukc.ukcPolicy = Number(Math.max(3.5, item.ukc.draft / 2).toFixed(1))
            item.ukc.ukcPolicy = 0.4
            item.ukc.safeDepth = Number((item.ukc.draft + item.ukc.squat + item.ukc.ukcPolicy + item.ukc.zoc).toFixed(2))
            item.ukc.isSafe = Number(item.minDepth + item.ukc.tide - item.ukc.safeDepth) > 0 ? 'YES' : 'NO'

          })

          this.isUKCReady = true
          this.$refs.pptable.refresh();


          // }).catch(() => {
          //
        })

      }
    },
    rowClass(item, type) {
      let isSafe = item.ukc.isSafe === 'YES'
      if (!isSafe)
        return 'table-danger'
    },

  }
}
</script>

<style scoped>

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #28a745;
  background-image: none !important;
  background-repeat: no-repeat;
  padding-right: 0px !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #f60e65;
  background-color: #ffabab;
  background-image: none !important;
  background-repeat: no-repeat;
  padding-right: 0px !important;
}

</style>

<style>
.custom-table {

}

.small-black-tide {
  font-size: 14px;
  font-weight: 400;
  color: black;

  min-width: 78px;

}

.small-black-tide input, .small-black-tide span {
  width: 100% !important;
  padding-left: 4px !important;
  padding-right: 0 !important;
  display: inline-block !important;
}

.small-black-zoc {
  font-size: 14px;
  font-weight: 400;
  color: black;

  min-width: 64px;

}

.small-black-zoc input, .small-black-zoc span {
  width: 100% !important;
  padding-left: 4px !important;
  padding-right: 0 !important;
  display: inline-block !important;
}

.small-black-time {
  font-size: 14px;
  font-weight: 400;
  color: black;

  min-width: 110px;

}

.small-black-time input, .small-black-time span {
  width: 100% !important;
  padding-left: 4px !important;
  padding-right: 0 !important;
  display: inline-block !important;
}

.custom-table td {
  min-width: 100px;
}

.custom-table td span > input[type="number"] {
  width: 100%;
  margin: 0;
  display: inline-block;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>