<template>
  <div>
    <b-card v-if="routesStore.getShipWaypointsCount > 0 && zoneInfo === ''" no-body bg-variant="light" class="mt-2 pl-3">
      <b-row>
        <b-col>
          <span class="smt2s">Start time: {{ routeTime() }}</span><br>
          <span class="smt2s">ETA: {{
              datem(routesStore.getShipLegInfo(routesStore.getShipWaypointsCount - 1).fromStartSeconds)
            }}</span><br>
        </b-col>
        <b-col>
          <span class="smt2s">Total waypoints: {{ routesStore.getShipWaypointsCount }}</span><br>
          <span class="smt2s">Total length: {{
              (routesStore.getShipLegInfo(routesStore.getShipWaypointsCount - 1).fromStartMeters * 0.000539957).toFixed(1)
            }} nm</span>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="true || routesStore.getShipWaypointsCount > 0 && zoneInfo === ''" no-body bg-variant="light"
            class="mt-2 pl-3 pb-10">
      <b-row>
        <b-col class="pb-0">
          <b-form-checkbox size="sm" v-model="showHazards">
            <span class="smt2s">Show hazards</span>
          </b-form-checkbox>
        </b-col>
        <b-col class="pb-0">
          <b-form-checkbox size="sm" v-model="showOnlyDangerLegs">
            <span class="smt2s">Show only danger legs</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>


    <b-card v-if="zoneInfo !== '' && routesStore.getShipWaypointsCount > 0" no-body bg-variant="light" class="mt-2 pl-3">
      <b-row>
        <b-col>
          <div v-html="zoneInfoTitle"/>
        </b-col>
        <b-col>
          <b-button class="border-0 float-right" size="sm" variant="outline-danger" @click="clearZoneInfo()">
            <b-icon icon="x" font-scale="1.5"></b-icon>
          </b-button>
        </b-col>
      </b-row>
      <div v-html="zoneInfo"/>
    </b-card>

    <b-table sticky-header select-mode="single" ref="wptable" id="routes-table" style="max-height: 300px;"
             @row-clicked="onRowClicked" :items="items" :fields="visible_fields"
             :tbody-tr-class="rowClass" class="mt-2" primary-key="id"
             :filter-function="filterTable" :filter="showOnlyDangerLegsFilter">

      <template #cell(selected)="data">
        <template v-if="data.index === currentRowIndex">
          <b-icon icon="play-fill"></b-icon>
        </template>
      </template>

      <template #cell(id)="data">
        <span v-if="data.index === currentRowIndex" class='smt2s'>
          <b><span class='smt2s'>
              <a class="blackref" href="#" @click='showLeg(data.item, data.item.id)'>{{
                  data.item.id
                }} </a>
            </span></b>

        </span>
        <span v-else class='smt2s'>
              <a class="blackref" href="#" @click='showLeg(data.item, data.item.id)'>{{
                  data.item.id
                }} </a>
            </span>
      </template>

      <template #cell(lat)="row">
          <span v-if="row.index === currentRowIndex" class='smt2s'>
            <b><a class="blackref" href="#" @click='showLeg(row.item, row.item.id)'>
              {{ row.item.lat.toFixed(4) }}<br>{{ row.item.lon.toFixed(4) }}</a>
            </b>
          </span>
        <span v-else class='smt2s'>
            <a class="blackref" href="#" @click='showLeg(row.item, row.item.id)'>
              {{ row.item.lat.toFixed(4) }}<br>{{ row.item.lon.toFixed(4) }}</a>
          </span>
      </template>

      <template #cell(speed)="data">
        <span v-if="data.index === currentRowIndex" class='smt2s'>
          <b>{{ routesStore.getShipSpeed }} kn</b>
        </span>
        <span v-else class='smt2s'>
            {{ routesStore.getShipSpeed }} kn
        </span>
      </template>

      <template #cell(eta)="data">
        <div v-if="data.index === currentRowIndex">
          <b>
          <span class='smt2s' v-if="routesStore.getShipWaypointsCount > 0 && routesStore.getShipLegInfo(data.item.id)">
            <span v-if="routesStore.getShipStartDate === '' || routesStore.getShipStartTime === ''">
              {{
                new Date((routesStore.getShipLegInfo(data.item.id).fromStartSeconds ?? 0) * 1000).toISOString().substring(11, 16)
              }}
            </span>
            <span v-else>
              {{ datem(routesStore.getShipLegInfo(data.item.id).fromStartSeconds) }}
            </span>
          </span>
          </b>
        </div>
        <div v-else>
          <span class='smt2s' v-if="routesStore.getShipWaypointsCount > 0 && routesStore.getShipLegInfo(data.item.id)">
            <span v-if="routesStore.getShipStartDate === '' || routesStore.getShipStartTime === ''">
              {{
                new Date((routesStore.getShipLegInfo(data.item.id).fromStartSeconds ?? 0) * 1000).toISOString().substring(11, 16)
              }}
            </span>
            <span v-else>
              {{ datem(routesStore.getShipLegInfo(data.item.id).fromStartSeconds) }}
            </span>
          </span>
        </div>
      </template>

      <template #cell(dangers)="data">
        <div v-if="showHazards">
          <br><br>
          <span class='smt2s'>
            <div v-for="(item) in getDangers(data.item.id)" class="mt-2">
              <a href="#" @click='showZone(data.item.id, item.id, item.points, item.type)'>{{
                  item.name
                }} </a>
            </div>
          </span>
        </div>
      </template>

      <template #cell(length)="data">
        <div v-if="data.index === currentRowIndex">
          <b>
          <span class='smt2s' v-if="routesStore.getShipWaypointsCount > 0 && routesStore.getShipLegInfo(data.item.id)">
            <div v-if="routesStore.getShipLegInfo(data.item.id).fromPreviousMeters < 1000">
              {{ (routesStore.getShipLegInfo(data.item.id).fromPreviousMeters).toFixed(1) }}m,
            </div>
            <div v-else>
              {{ (routesStore.getShipLegInfo(data.item.id).fromPreviousMeters * 0.000539957).toFixed(1) }}nm,
            </div>
            {{ (routesStore.getShipLegInfo(data.item.id).fromStartMeters * 0.000539957).toFixed(1) }}nm
          </span>
          </b>
        </div>
        <div v-else>
          <span class='smt2s' v-if="routesStore.getShipWaypointsCount > 0 && routesStore.getShipLegInfo(data.item.id)">
            <div v-if="routesStore.getShipLegInfo(data.item.id).fromPreviousMeters < 1000">
              {{ (routesStore.getShipLegInfo(data.item.id).fromPreviousMeters).toFixed(1) }}m,
            </div>
            <div v-else>
              {{ (routesStore.getShipLegInfo(data.item.id).fromPreviousMeters * 0.000539957).toFixed(1) }}nm,
            </div>
            {{ (routesStore.getShipLegInfo(data.item.id).fromStartMeters * 0.000539957).toFixed(1) }}nm
          </span>
        </div>
      </template>
    </b-table>
    <!--    </b-overlay>-->
  </div>
</template>

<script>
import {eventBus, routes_map} from "@/main";
import {useRoutesStore} from "@/stores/routes";
import {useMomentaryStore} from "@/stores/momentary";
import * as dayjs from 'dayjs/dayjs.min'
import {mapStores} from "pinia";
import {createVoyagePlan} from "@/views/routes/components/VoyagePlan";
import {myAxios, myAxiosRetry} from "@/services/myAxios";
import router from "@/router";

let customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  name: "RouteTable",
  data() {
    return {
      isWeatherTable: false,
      spinner: false,
      prevRowIndex: -1,
      currentRowIndex: -1,
      centerRowIndex: -1,
      focusWPIndex: -1,
      isShowSearch: false,
      searchFilter: null,
      selectedCell: null,
      zoneInfoTitle: "",
      zoneInfo: "",
      showHazards: true,
      showOnlyDangerLegsFilter: '',
      showOnlyDangerLegs: false,
      filterCriteria: '2'
    }
  },

  computed: {
    ...mapStores(useRoutesStore, useMomentaryStore),
    // routePoints() {
    //   return this.routesStore.routeResponse.routePoints
    // },
    // routeLegs() {
    //   return this.routesStore.routeResponse.routeLegs
    // },
    // routeDangers() {
    //   return this.routesStore.routeResponse.dangerObjects
    // },
    // routeSpeed() {
    //   return this.routesStore.getSpeed
    // },

    visible_fields() {
      if (this.isWeatherTable) {
        return [
          {key: 'selected', label: '', tdClass: 'small-black', thClass: 'small-gray'},
          {key: 'id', label: '#', tdClass: 'small-black', thClass: 'small-gray'},
          // {key: 'safety', label: 'D', tdClass: 'small-black', thClass: 'small-gray'},
          // {key: 'warnings', label: 'W', tdClass: 'small-black', thClass: 'small-gray'},
          {key: 'lat', label: 'Lat/Lon', tdClass: 'small-black', thClass: 'small-gray'},
        ]

      }

      return [
        {key: 'selected', label: '', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'id', label: '#', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'safety', label: 'D', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'warnings', label: 'W', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'lat', label: 'Lat/Lon', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'dangers', label: 'Hazards', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'speed', label: 'Speed', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'eta', label: 'ETA', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'length', label: 'Length', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'edit', label: ''}
      ]
    },
    items() {
      let items = this.routesStore.getShipWaypoints;
      return items
    },
  },
  watch: {
    'showOnlyDangerLegs'(newVal, oldVal) {
      if (newVal)
        this.showOnlyDangerLegsFilter = '1'
      else
        this.showOnlyDangerLegsFilter = null
    }
  },
  mounted() {
    eventBus.$off('select-routetable-row')
    eventBus.$off('set-center-index')
    eventBus.$off('focus-center-index')
    eventBus.$off('move-current')
    eventBus.$off('move-next')
    eventBus.$off('move-prev')
    eventBus.$off('clear-current-wp-index')

    eventBus.$on('clear-current-wp-index', () => {
      this.currentRowIndex = -1
      eventBus.$emit('show-wp-index', this.currentRowIndex)
      eventBus.$emit('clear-current-wp-index-weather')
      eventBus.$emit('clear-current-wp-index-zones')
    })

    eventBus.$on('move-current', () => {
      eventBus.$emit('move-current-weather')
      eventBus.$emit('move-current-zones')
      if (this.prevRowIndex !== -1) {
        this.currentRowIndex = this.prevRowIndex
        this.selectRow(this.currentRowIndex)
      }
    })

    eventBus.$on('move-next', () => {
      console.log("qqq", this.currentRowIndex)

      eventBus.$emit('move-next-weather')
      eventBus.$emit('move-next-zones')
      if (this.currentRowIndex !== -1) {
        this.currentRowIndex = this.currentRowIndex === this.routesStore.getShipWaypointsCount - 1 ? 0 : this.currentRowIndex + 1
        this.prevRowIndex = this.currentRowIndex
        this.selectRow(this.currentRowIndex)
      } else {
        this.selectRow(this.focusWPIndex)
      }

      eventBus.$emit('switch-popup', this.currentRowIndex)
      eventBus.$emit('show-dangers-in-wp', this.currentRowIndex)
    })

    eventBus.$on('move-prev', () => {
      eventBus.$emit('move-prev-weather')
      eventBus.$emit('move-prev-zones')
      if (this.currentRowIndex !== -1) {
        this.currentRowIndex = this.currentRowIndex === 0 ? this.routesStore.getShipWaypointsCount - 1 : this.currentRowIndex - 1
        this.prevRowIndex = this.currentRowIndex
        this.selectRow(this.currentRowIndex)
      } else {
        this.selectRow(this.focusWPIndex)
      }

      eventBus.$emit('switch-popup', this.currentRowIndex)
      eventBus.$emit('show-dangers-in-wp', this.currentRowIndex)
    })

    eventBus.$on('set-center-index', (index) => {
      this.centerRowIndex = index
    })

    eventBus.$on('set-focus-index', (index) => {
      this.focusWPIndex = index
      eventBus.$emit('set-focus-index-weather', index)
      eventBus.$emit('set-focus-index-zones', index)
    })

    eventBus.$on('select-routetable-row', (id) => {
      eventBus.$emit('select-routetable-row-weather', id)
      eventBus.$emit('select-routetable-row-zones', id)
      this.showOnlyDangerLegs = false
      this.currentRowIndex = id
      this.$refs.wptable.selectRow(id);
      setTimeout(() => {
        const tbody = this.$refs.wptable.$el.querySelector('tbody')
        const row = tbody.querySelectorAll('tr')[id]
        row.scrollIntoView({block: 'center'})
      }, 100)
      eventBus.$emit('show-wp-index', id)
    })
  },
  methods: {
    filterTable(row, filter) {
      // if (this.showOnlyDangerLegs)
      //   return true

      // console.log("aaaa1", row.safety)
      // console.log("aaaa2", row)
      // console.log("aaaa3", filter)


      // return true
      //
      // console.log("aaaa1", row.safety)
      // console.log("aaaa2", row)
      // console.log("aaaa3", filter)
      //
      if (this.showOnlyDangerLegs) {
        if (!row.isDepthSafe)
          return true
      } else
        return false
    },
    rowClass(item, type) {
      let legSafety = this.routesStore.getShipLegSafety(item.id)
      let legWarnings = this.routesStore.getShipLegWarnings(item.id)

      if (!legSafety)
        return 'table-danger'
      else if (legWarnings === 'warnings')
        return 'table-warning'

      // let allWarnings = this.routeDangers.filter(item => item.isDanger === true)
      // let legWarnings = allWarnings.filter(item => item.legIndex === item.id)
      // if (legWarnings.length > 0)
      //   return 'table-warning'


      // else if (item.isDanger)
      //   return 'table-warning'

      // if (!item.isDanger || type !== 'row') return
      // if (item.isDanger) return 'table-danger'

      // let rowClass = ''
      // let routeLegs = this.routesStore.routeLegs
      // if (routeLegs[item.legId].safety === 2)
      //   rowClass = 'table-danger'
      //
      // return rowClass


    },
    editCellHandler(data, name) {
      this.items[data.index].isEdit = true;
      this.selectedCell = name
    },
    getDangers(index) {

      // return (e.isDanger ||
      //     (!e.isDanger && e.name !== "Depth contour" && e.name !== "Depth area")

      let dangers = this.routesStore.getShipLegHazards(index)
      let hazards = dangers.filter(function (e) {
        return (e.isDanger || (!e.isDanger && e.name !== "Depth contour" && e.name !== "Depth area"))
      })

      return hazards




      // const dangers = this.getHazards(index)

      // const dangers = this.$store.state.routes.dangers.filter(function (e) {
      //   return (e.isDanger === true ||
      //       (e.isDanger === false && e.name === "Traffic separation zone") ||
      //       (e.isDanger === false && e.name === "Inshore traffic zone") ||
      //       (e.isDanger === false && e.name === "Caution area") ||
      //       (e.isDanger === false && e.name === "Offshore production area") ||
      //       (e.isDanger === false && e.name === "Sea-plane landing area") ||
      //       (e.isDanger === false && e.name === "Submarine transit lane") ||
      //       (e.isDanger === false && e.name === "Anchorage area") ||
      //       (e.isDanger === false && e.name === "Marine farm/culture") ||
      //       (e.isDanger === false && e.name.startsWith("Restricted area"))
      //   )
      // })

      // const legDangers = dangers.filter(function (e) {
      //   return e.legIndex === index + 1
      // })
      //
      // let items = []
      // legDangers.forEach(item => {
      //   items.push({name: item.name, type: item.locationType, points: item.points, id: item.id})
      // })
      //
      // return items
    },
    showZone(index, id, points, type) {
      console.log("aaa1")
      eventBus.$emit('show-dangers-in-wp', index)
      myAxios.get(`https://mwend.bgeo.fi/api/v1/objectinfo?id=${id}`).then(resp => {
        this.showZoneInfo(resp.data)
        routes_map.drawDangerObject({lines: resp.data.lines, type: type, zoom: true})
      });
    },
    showZoneInfo(data) {
      this.zoneInfoTitle = "<b><span class='smt2s'>" + data.name + "</span></b>"
      let text = ""
      data.attributes.forEach((item) => {
        if (item.name !== "Textual description")
          text += "<span class='smt2s'>" + item.name + ": " + item.value + "</span><br>"
        else {
          text += "<span class='smt2s'><details><summary>" + item.name + "...</summary>" + item.value + "</span></details><br>"
        }
      })

      this.zoneInfo = text
    },
    clearZoneInfo() {
      this.zoneInfoTitle = ""
      this.zoneInfo = ""
      routes_map.clearDangerObject()

    },
    routeTime() {
      let datetime = dayjs(this.routesStore.getShipStartDate + " " + this.routesStore.getShipStartTime).format("DD MMM HH:mm")
      if (datetime === "Invalid date")
        datetime = "N/A"
      return datetime
    },
    datem(seconds) {
      let datetime = dayjs(this.routesStore.getShipStartDate + " " + this.routesStore.getShipStartTime)
      let datetimenew = datetime.add(seconds, "seconds")
      let shortdate = datetimenew.format("DD/MM HH:mm")
      return shortdate
    },
    selectRow(index) {
      this.showOnlyDangerLegs = false
      this.clearZoneInfo()
      this.$refs.wptable.unselectRow(index);
      setTimeout(() => {
        this.$refs.wptable.selectRow(index);
      }, 100)
      this.currentRowIndex = index

      eventBus.$emit('click-wp', index)
    },

    onCellClicked(id) {
      if (this.$refs.foliotable1) {
        let x = this.$refs.foliotable1.computedItems;
        let row = this.$refs.foliotable1.computedItems.findIndex(x => x.id === id);
        console.log(id, row, this.items);
        this.$refs.foliotable1.selectRow(row);


        this.$nextTick(() => {
          const rowElement = this.$refs.foliotable1.$el.querySelector(`tbody tr:nth-child(${row + 1})`);
          if (rowElement) {
            rowElement.scrollIntoView({behavior: 'smooth', block: 'center'});
          }
        });
      }
    },

    showLeg(item, index) {
      this.clearZoneInfo()
      console.log("aaaa", item)
      console.log("aaaa", index)
      this.onRowClicked(item, index)
    },
    onRowClicked(row, index) {
      this.clearZoneInfo()
      eventBus.$emit('close-popup')
      // this.items.forEach((item, i) => {
      //   item.isEdit = false
      // })
      console.log("iii", index, row.id)
      this.currentRowIndex = row.id
      // console.log("aaaa1", row)

      // setTimeout(function () {

      // routes_map.aaa(row, row.id)
      routes_map.selectWaypoint(row.id)

      // eventBus.$emit('wp-selected', row, row.id)

      eventBus.$emit('show-dangers-in-wp', row.id)

      // setTimeout(function () {
      //   eventBus.$emit('click-wp', row.id, true)
      //   // eventBus.$emit('show-dangers-in-wp', row.id)
      // }, 1000)
    },
    // onRowSelected(items) {
    //   // alert(2)
    //   console.log("aaaaa2", items[0])
    //   // eventBus.$emit('wp-selected', items[0])
    // },
  }
}
</script>

<style>
.btn-block-50 {
  display: block;
  width: 50%;
}
</style>

<style scoped>
/deep/ .table > tbody > tr.b-table-row-selected,
/deep/ .table > tbody > tr.b-table-row-selected > td,
/deep/ .table > tbody > tr.b-table-row-selected > th {
  background-color: rgba(124, 124, 124, 0.1);
  //border:1px solid red;
}

.blackref {
  color: dimgray;
}

</style>