<template>
  <div style="z-index:2;" id="foliomap" ref="foliomap" :style="{ height: height + 'px', minHeight: '400px' }"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import {FolioLeaf} from "@/my-leaf/charts/folio-leaf";
import {mapStores} from "pinia";
import {useCatalogsStore} from "@/stores/catalogs";
import {useDeviceStore} from "@/stores/device";

export default {
  name: "FolioMap",
  props: ['height'],
  data() {
    return {
      foliomap: new FolioLeaf(),
    }
  },
  methods: {
    showFolioCell(cellName) {
      this.foliomap.highlightCell(cellName)
    },
    init_map() {
      return new Promise((resolve, reject) => {
        this.foliomap.init(this.$refs.foliomap);

        const allCharts = this.catalogsStore.getItems;
        const purchasedList = this.deviceStore.orderedData.ukho.avcs;
        const purchasedIds = purchasedList.map(item => item.name);

        // Filter for purchased/owned cells only
        const purchasedCharts = allCharts.filter(chart => {
          return purchasedIds.includes(chart.id);
        });

        console.log("aaa1", purchasedCharts)
        this.foliomap.showRoute()
        this.foliomap.drawAllCells(purchasedCharts);
        resolve();
      });

    },
  },

  computed: {
    ...mapStores(useDeviceStore, useCatalogsStore),
  },

};
</script>

<style>
#foliomap {
  border: solid 1px dimgray;
}

.leaflet-interactive:hover {
  stroke: #ffa011;
  stroke-width: 4;
}
</style>