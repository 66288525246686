import {useRoutesStore} from "@/stores/routes";
import {useMomentaryStore} from "@/stores/momentary";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import store from "@/store";
import dayjs from "dayjs";

let routesStore, momentaryStore
let prevPublications = '--'
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function createVoyagePlan() {
    routesStore = useRoutesStore()
    momentaryStore = useMomentaryStore()
    let rows = []
    let rowsUKC = []

    let columns = [];
    columns.push({text: '#', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'Lat/Lon', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'Planned\nspeed', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'ETA', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'Bearing', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'Trip', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'Minimum\nUKC', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'Charts', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'Publications', style: 'tableHeader', alignment: 'center'});
    columns.push({text: 'Comments', style: 'tableHeader', alignment: 'center'});

    rows.push(columns)

    let columnsUKC = [];
    columnsUKC.push({text: '#', style: 'tableHeader', alignment: 'center'});
    columnsUKC.push({text: 'Chart depth', style: 'tableHeader', alignment: 'center'});
    columnsUKC.push({text: 'Safe', style: 'tableHeader', alignment: 'center'});
    columnsUKC.push({text: 'Safe Depth', style: 'tableHeader', alignment: 'center'});
    columnsUKC.push({text: 'UKC', style: 'tableHeader', alignment: 'center'});
    columnsUKC.push({text: 'Waters type', style: 'tableHeader', alignment: 'center'});
    columnsUKC.push({text: 'Draft', style: 'tableHeader', alignment: 'center'});
    columnsUKC.push({text: 'Speed', style: 'tableHeader', alignment: 'center'});
    columnsUKC.push({text: 'UKC Policy', style: 'tableHeader', alignment: 'center'});
    columnsUKC.push({text: 'Squat', style: 'tableHeader', alignment: 'center'});
    columnsUKC.push({text: 'Tide', style: 'tableHeader', alignment: 'center'});
    columnsUKC.push({text: 'ZOC', style: 'tableHeader', alignment: 'center'});

    rowsUKC.push(columnsUKC)

    routesStore.getShipWaypoints.forEach((item, i) => {
        let values = []
        values.push({text: i, style: 'tableText'});
        values.push({text: getLatLon(item), style: 'tableText'});
        values.push({text: getSpeed(i), style: 'tableText'});
        values.push({text: getETA(i), style: 'tableText'});
        values.push({text: getBearing(i), style: 'tableText'});
        values.push({text: getTrip(i), style: 'tableText'});
        values.push({text: getUKC(i), style: 'tableText'});
        values.push({text: getCharts(i), style: 'tableText'});
        values.push({text: getPublications(i), style: 'tableText'});
        values.push({text: getDangers(i), style: 'tableText'});
        rows.push(values)
        // rowsUKC.push(values)
    })

    routesStore.getShipWaypoints.forEach((item, i) => {
        let values = []
        values.push({text: i, style: 'tableText'});
        values.push({text: getUKC_ChartDepth(i), style: 'tableText'});
        values.push({text: getUKC_Safe(i), style: 'tableText'});
        values.push({text: getUKC_SafeDepth(i), style: 'tableText'});
        values.push({text: getUKC_UKC(i), style: 'tableText'});
        values.push({text: getUKC_Waters(i), style: 'tableText'});
        values.push({text: getUKC_Draft(i), style: 'tableText'});
        values.push({text: getUKC_Speed(i), style: 'tableText'});
        values.push({text: getUKC_UKCPolicy(i), style: 'tableText'});
        values.push({text: getUKC_Squat(i), style: 'tableText'});
        values.push({text: getUKC_Tide(i), style: 'tableText'});
        values.push({text: getUKC_ZOC(i), style: 'tableText'});
        rowsUKC.push(values)
    })

    let dd = {
        pageOrientation: 'landscape',
        content: [
            {
                table: {
                    widths: ['*', '*', '*'],
                    headerRows: 1,
                    body: [
                        [{text: 'PASSAGE PLAN', style: 'tableHeader', colSpan: 3, alignment: 'center'}, {}, {}],
                        ['Date: ' + getStartDate(), {text: 'Route Name: ' + getRouteName(), colSpan: 2}, {}],
                        ['Total distance: ' + getTotalDistance(), {rowSpan: 4, text: 'Additional Notes:'}, '' ],
                        // ['Tide PILOTAGE Port:', {rowSpan: 2, text: 'Additional Notes:'}, 'Total distance: ' + getTotalDistance() ],
                        [' ', ' ', ' '],
                        [' ', ' ', ' '],
                        [' ', ' ', ' '],

                    ]
                }
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['auto', 'auto', 'auto', 'auto', 'auto', 60, 'auto', 'auto', 110, '*'],
                    body: rows
                }
            },

            {
                table: {
                    widths: ['*'],
                    headerRows: 1,
                    body: [
                        [{text: 'UKC', style: 'tableHeader', alignment: 'center'}],
                    ]
                }
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['*', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%'],
                    body: rowsUKC
                }
            },
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10]
            },
            subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
            },
            tableExample: {
                margin: [0, 5, 0, 15]
            },
            tableHeader: {
                bold: true,
                fontSize: 11,
                color: 'black'
            },
            tableText: {
                fontSize: 11,
                color: 'gray'
            }
        },
        defaultStyle: {
            // alignment: 'justify'
        }
    }

    pdfMake.createPdf(dd).download("passage-plan.pdf", {});

}

function getStartDate() {
    const date = routesStore.getShipStartDate
    return date
}

function getRouteName() {
    const name = routesStore.getRouteName
    return name
}

function getTotalDistance() {
    const distance = getTrip(routesStore.getShipWaypointsCount - 1)
    return distance
}

function getLatLon(item) {
    const latlon = item.lat.toFixed(6) + "\n" + item.lon.toFixed(6)
    return latlon
}

function getSpeed(index) {
    let speed = ''
    if (index > 0) {
        speed = routesStore.getImportedWaypointSpeed(index) + ' kn'
    }

    if (speed.startsWith("1797"))
        speed = ''
    return speed
}

function getBearing(index) {
    const radians = routesStore.getShipWaypoints[index].info.course
    let degrees = radians * (180 / Math.PI)
    degrees = degrees < 0 ? 360 + degrees : degrees;
    // const val = +degrees.toFixed(1);
    degrees = degrees.toFixed(1);
    return degrees + 'º'
}

function getTrip(index) {
    let units
    let tripFromStart = routesStore.getShipWaypoints[index].info?.fromStartMeters ?? 0
    if (tripFromStart >= 1000) {
        tripFromStart *= 0.000539957
        units = ' nm'
        tripFromStart = tripFromStart.toFixed(1)
    } else {
        units = ' m'
        tripFromStart = tripFromStart.toFixed(0)
    }
    return tripFromStart + units
}

function getUKC(index) {
    let units = ' m'
    let ukc = routesStore.getShipWaypoints[index].ukc?.ukc ?? 0
    return ukc + units
}

function getETA(index) {
    const secondsFromStart = routesStore.getShipWaypoints[index].info?.fromStartSeconds ?? 0
    const startTime = dayjs(routesStore.getShipStartDate + " " + routesStore.getShipStartTime)
    let ETA = startTime.add(secondsFromStart, "seconds").format("DD/MM HH:mm")
    return ETA
}

function getCharts(index) {
    let chartsList = ""
    if (index + 1 in momentaryStore.route.chartsbylegs) {
        const charts = momentaryStore.route.chartsbylegs[index + 1]
        charts.forEach(item => {
            chartsList += item + "\n"
        })
    }
    return chartsList
}

function getPublications(index) {
    let pubsList = ""
    if (index + 1 in momentaryStore.route.pubsbylegs) {
        const pubs = momentaryStore.route.pubsbylegs[index + 1]
        pubs.forEach(item => {
            pubsList += item + "\n"
        })
    }

    if (prevPublications === pubsList)
        pubsList = '(the same as above)'
    else
        prevPublications = pubsList

    return pubsList
}

function getDangers(index) {

    // TODO
    return []

    const allDangers = routesStore.getShipLegHazards
    const legDangers = allDangers.filter(function (e) {
        return e.legIndex === index + 1
    })

    // eslint-disable-next-line no-debugger
    // debugger
    let dangersNames = 'Dangers: '
    let dangerObjects = new Set()
    legDangers.forEach((item) => {
        dangerObjects.add(item.name)
    })

    dangerObjects.forEach((item) => {
        dangersNames += item + ", "
    })
    dangersNames = dangersNames.replace(/,\s$/, "");
    dangersNames = dangersNames.replace(/Dangers:\s$/, "");

    return dangersNames
}

function getUKC_UKC(index) {
    let units = ' m'
    let val = routesStore.getShipWaypoints[index].ukc?.ukc ?? 0
    return val + units
}

function getUKC_ZOC(index) {
    let units = ' m'
    let val = routesStore.getShipWaypoints[index].ukc?.zoc ?? 0
    return val + units
}

function getUKC_UKCPolicy(index) {
    let units = ' m'
    let val = routesStore.getShipWaypoints[index].ukc?.ukcPolicy ?? 0
    return val + units
}

function getUKC_Safe(index) {
    let val = routesStore.getShipWaypoints[index].ukc?.isSafe ?? false
    return val ? 'YES' : 'NO'
}

function getUKC_Draft(index) {
    let units = ' m'
    let val = routesStore.getShipWaypoints[index].ukc?.draft ?? 0
    return val + units
}

function getUKC_Speed(index) {
    let units = ' kn'
    let val = routesStore.getShipWaypoints[index].ukc?.speed ?? 0
    return val + units
}

function getUKC_Squat(index) {
    let units = ' m'
    let val = routesStore.getShipWaypoints[index].ukc?.squat ?? 0
    return val + units
}

function getUKC_Tide(index) {
    let units = ' m'
    let val = routesStore.getShipWaypoints[index].ukc?.tide ?? 0
    return val + units
}

function getUKC_SafeDepth(index) {
    let units = ' m'
    let val = routesStore.getShipWaypoints[index].ukc?.safeDepth ?? 0
    return val + units
}

function getUKC_ChartDepth(index) {
    let units = ' m'
    let val = routesStore.getShipWaypoints[index].minDepth ?? 0
    return val + units
}

function getUKC_Waters(index) {
    let val = routesStore.getShipWaypoints[index].ukc?.isOpenWater ?? false
    return val ? 'Open' : 'Confined'
}
