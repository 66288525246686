<template>
  <div>
<!--    <b-card>-->
      <img class="mx-0" height="100px" src="https://img.freepik.com/free-psd/luxury-yacht-bow-watercraft-elegance-nautical-vessel_632498-25525.jpg">
<!--      {{drawShip()}}-->
<!--    </b-card>-->
  </div>
</template>

<script>

import {eventBus} from "@/main";
import {mapStores} from "pinia";
import {useRoutesStore} from "@/stores/routes";

export default {
  name: "Ship",
  mounted() {
  },
  computed: {
    ...mapStores(useRoutesStore),
  },
  methods: {
    drawShip() {
      return 'Ship'
    }
  }
}
</script>


<style scoped>

</style>